import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import InputField from 'reusableComponents/InputField/InputField'
import TermsAndConditions from 'components/TermsAndConditions'
import { breakpoints } from 'globalStyles'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  .tandC {
    margin-top: 18px;
    font-size: 12px;

    @media screen and ${breakpoints.sm_above} {
      font-size: 14px;
    }
    text-align: left;
  }
`
const BuzzerCode = ({ onValueChangeHandler, buzzerCode }) => {
  const { t } = useTranslation()
  return (
    <Container>
      <InputField
        id="buzzer-code"
        value={buzzerCode}
        maxLength={6}
        onChange={onValueChangeHandler}
        label={t('deliveryPreferencesSummary.buzzer_code_type')}
      />
      <span className="tandC">
        <Trans
          i18nKey="deliveryPreferencesSummary.terms_and_conditions_text"
          t={t}
          components={[<TermsAndConditions />]}
        />
      </span>
    </Container>
  )
}
BuzzerCode.propTypes = {
  onValueChangeHandler: PropTypes.func,
  buzzerCode: PropTypes.string,
}
BuzzerCode.defaultProps = {
  onValueChangeHandler: null,
  buzzerCode: '',
}
export default BuzzerCode
