import i18n from 'i18next'
import { format as formatDate, isDate } from 'date-fns'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { enCA as en, fr } from 'date-fns/locale'

const langIndex = 2
const locales = { en, fr }

i18n.on('languageChanged', () => {
  const [, ...paths] = window.location.pathname.split('/')

  if (!i18n.options.supportedLngs.includes(paths[langIndex])) {
    paths[langIndex] = i18n.options.fallbackLng
    const newPath = paths.reduce((acc, path) => (path ? `${acc}/${path}` : acc), '')
    window.location.replace(newPath)
  }
})

/* const generateNewDate = (inputDate) => {
  // Mange the timezone issue in the Date obj 
  const date = new Date(inputDate)
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000)
} */

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'fr'],
    fallbackLng: 'en',
    detection: {
      order: ['path'],
      lookupFromPathIndex: langIndex,
    },
    debug: true,
    interpolation: {
      //   escapeValue: false, // not needed for react as it escapes by default
      // eslint-disable-next-line default-param-last
      format: (inputDate, format = 'PPPp', lng) => {
        const date = inputDate instanceof Date ? inputDate : new Date(inputDate)
        if (isDate(date)) {
          const locale = locales[lng]
          if (format === 'short') return formatDate(date, 'PP', { locale })
          if (format === 'shortWithTime') return formatDate(date, 'PPp', { locale })
          if (format === 'long') return formatDate(date, 'PPPP', { locale })
          if (format === 'longWithTime') return formatDate(date, 'PPPPp', { locale })
          if (format === 'shortTime') return formatDate(date, 'HH:mm', { locale })
          if (format === 'shortTimeWithMeridiem') return formatDate(date, 'p', { locale })
          if (format === 'PPP') return formatDate(date, 'PPP', { locale })

          return formatDate(date, format, { locale })
        }
        return date
      },
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    },
  })

export default i18n
