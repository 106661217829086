import { configureStore } from '@reduxjs/toolkit'
import globalReducer from './global/globalSlice'
import shippingReducer from './shipping/shippingSlice'

export default configureStore({
  reducer: {
    global: globalReducer,
    shipping: shippingReducer,
  },
})
