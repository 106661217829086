import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import PurolatorLogo from 'reusableComponents/PurolatorLogo'
import { breakpoints } from 'globalStyles'
import Content from './Content'

const ModalLink = styled(Button).attrs({
  variant: 'link',
})`
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
`
const Body = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const TncCloseButton = styled(Button)`
  height: 50px;
  width: 126px;
  border-radius: 4px;
  padding: 9px 24px 9px 24px;

  @media screen and ${breakpoints.sm_above} {
    height: 55px;
    width: 137px;
    border-radius: 4px;
  }
`

const TermsAndConditions = ({ children }) => {
  const [show, setShow] = useState(false)
  const { t, i18n } = useTranslation()
  const open = () => setShow(true)
  const close = () => setShow(false)

  const modalProps = {
    show,
    onExit: close,
    onEscapeKeyDown: close,
    onHide: close,
    scrollable: true,
    fullscreen: true,
  }

  return (
    <>
      <ModalLink onClick={open}>{children}</ModalLink>
      <Modal {...modalProps}>
        <Modal.Header>
          <PurolatorLogo lang={i18n.language} />
        </Modal.Header>
        <Body>
          <Content />
          <TncCloseButton onClick={close}>{t('buttonActions.close')}</TncCloseButton>
        </Body>
      </Modal>
    </>
  )
}

TermsAndConditions.propTypes = {
  children: PropTypes.string.isRequired,
}

export default TermsAndConditions
