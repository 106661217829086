import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { BaseCard } from 'reusableComponents/Cards'
import TrackingNumberSVG from 'assets/svg/tracking_number_icon.svg'
import { DropdownWithDisplay } from 'reusableComponents/DropdownWithDisplay'
import { selectSortPackagePinsByStatus, selectVendor } from 'store/shipping/shippingSelectors'
import { TrackingStyle, VendorStyle, LogoStyle } from './Tracking.style'

const getImagePath = (vendorName) => {
  const imageName = vendorName.replaceAll(' ', '-')
  const imagePath = `${process.env.PUBLIC_URL}/assets/logo/${imageName}.svg`

  return (
    <LogoStyle>
      <img className="imageRight" alt="vendor" src={imagePath} />
    </LogoStyle>
  )
}
const getVendor = (vendorName) => {
  if (vendorName === 'Dell' || vendorName === 'Canadian Tire') {
    return getImagePath(vendorName)
  }
  return <VendorStyle> {vendorName} </VendorStyle>
}

const TrackingNumber = ({ onSelectPinHandler }) => {
  const { t } = useTranslation()
  const shipmentPins = useSelector(selectSortPackagePinsByStatus)
  const vendorName = useSelector(selectVendor)
  const vendor = getVendor(vendorName)
  const numOfPackage = shipmentPins.length

  return (
    <BaseCard
      style={{ padding: '0px 16px' }}
      title={t('tracking_no')}
      iconPath={TrackingNumberSVG}
      companyLogo={vendor}
    >
      <TrackingStyle>
        {numOfPackage > 1 ? (
          <DropdownWithDisplay items={shipmentPins} selectedValueHandler={onSelectPinHandler} />
        ) : (
          shipmentPins
        )}
      </TrackingStyle>
    </BaseCard>
  )
}

TrackingNumber.propTypes = {
  onSelectPinHandler: PropTypes.func.isRequired,
}

export default TrackingNumber
