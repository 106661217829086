import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { OutlineButton } from 'reusableComponents/Buttons/Buttons'
import DropOffLocationIcon from 'assets/svg/house-building.svg'
import DropOffLocationIconDisabled from 'assets/svg/house-building-grey.svg'
import BuzzerCodeIcon from 'assets/svg/buzzer-code.svg'
import BuzzerCodeIconDisabled from 'assets/svg/buzzer-code-grey.svg'
import DeliveryInstructionsIcon from 'assets/svg/house-person-leave.svg'
import DeliveryInstructionsIconDisabled from 'assets/svg/house-person-leave-grey.svg'
import SuccessIcon from 'assets/svg/circle-check-unfilled.svg'
import StoredIcon from 'assets/svg/circle-check-filled.svg'
import { breakpoints, colors } from 'globalStyles'
import {
  selectAllowFlagsForPreferences,
  selectIsUserVerified,
  selectDeliverySummary,
} from 'store/shipping/shippingSelectors'
import { setShipmentPreferences } from 'store/shipping/shippingSlice'
import Verification from 'components/Verification'
import SetPreferencesPopUp from './SetPreferencesPopUp'

const PreferencesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${breakpoints.desktop} {
    flex-direction: column;
    align-items: center;
    justify-content: unset;
    gap: 40px;
    border-top: 1.5px solid ${colors.primary_blue};
    border-left: 1.5px solid ${colors.primary_blue};
    border-bottom: 1.5px solid ${colors.primary_blue};
    border-radius: 8px 0px 0px 8px;
    width: 140px;
    padding: 10px 0px 10px 0px;
  }
`
const Heading = styled.h3`
  text-align: center;
  display: block;
  color: ${colors.primary_blue};
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 25px;
  @media ${breakpoints.desktop} {
    font-size: 16px;
    line-height: 22px;
    width: 135px;
    margin-bottom: 20px;
  }
`

const SetPreferences = () => {
  const [showPopUp, setShowPopUp] = useState(false)
  const [showVerificationPopUp, setShowVerificationPopUp] = useState(false)
  const [popUpType, setPopUpType] = useState('dropOffLocation')
  const [successValue, setSuccessValue] = useState({
    dropOffLocationVal: false,
    buzzerCodeVal: false,
    additionalInstrVal: false,
  })

  const {
    buzzerCode: allowBuzzerCode,
    additionalInstructions: allowAdditionalInstr,
    location: allowDeliveryLoc,
  } = useSelector(selectAllowFlagsForPreferences)

  const {
    deliveryLocation,
    buzzerCode,
    additionalDeliveryInstructions,
    signatureRequiredForAdditionalInstructions,
  } = useSelector(selectDeliverySummary)

  const isVerifiedUser = useSelector(selectIsUserVerified)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const onClickPopUpAction = (popupType) => {
    if (isVerifiedUser) {
      setShowPopUp(true)
      setPopUpType(popupType)
    } else {
      setShowVerificationPopUp(true)
    }
  }

  const onHidePopUp = () => {
    setShowPopUp(false)
  }

  const onSaveDeliveryPreferences = (instr, buzzCode, loc, remoteSignaturePreference) => {
    dispatch(setShipmentPreferences(instr, buzzCode, loc, remoteSignaturePreference))
    switch (popUpType) {
      case 'dropOffLocation':
        setSuccessValue({ ...successValue, dropOffLocationVal: true })
        break
      case 'buzzerCode':
        setSuccessValue({ ...successValue, buzzerCodeVal: true })
        break
      case 'deliveryInstructions':
        setSuccessValue({ ...successValue, additionalInstrVal: true })
        break
      default:
        break
    }
  }

  return (
    <div>
      <Heading>{t('deliveryPreferencesSummary.set_preferences')}</Heading>
      <PreferencesContainer>
        <OutlineButton
          icon={allowDeliveryLoc ? DropOffLocationIcon : DropOffLocationIconDisabled}
          label={t('deliveryPreferencesSummary.drop_off_text')}
          disable={!allowDeliveryLoc}
          success={successValue.dropOffLocationVal}
          stored={!!deliveryLocation}
          successIcon={SuccessIcon}
          storedIcon={StoredIcon}
          transitionTime={15}
          action={() => onClickPopUpAction('dropOffLocation')}
        />

        <OutlineButton
          icon={allowBuzzerCode ? BuzzerCodeIcon : BuzzerCodeIconDisabled}
          label={t('deliveryPreferencesSummary.buzzer_code')}
          disable={!allowBuzzerCode}
          success={successValue.buzzerCodeVal}
          stored={!!buzzerCode}
          successIcon={SuccessIcon}
          storedIcon={StoredIcon}
          transitionTime={15}
          action={() => onClickPopUpAction('buzzerCode')}
        />

        <OutlineButton
          icon={allowAdditionalInstr ? DeliveryInstructionsIcon : DeliveryInstructionsIconDisabled}
          label={t('deliveryPreferencesSummary.del_instructions')}
          disable={!allowAdditionalInstr || signatureRequiredForAdditionalInstructions}
          success={successValue.additionalInstrVal}
          stored={!!additionalDeliveryInstructions}
          successIcon={SuccessIcon}
          storedIcon={StoredIcon}
          transitionTime={15}
          action={() => onClickPopUpAction('deliveryInstructions')}
        />

        <Verification show={showVerificationPopUp} onHide={setShowVerificationPopUp} />
      </PreferencesContainer>

      <SetPreferencesPopUp
        show={showPopUp}
        onHide={onHidePopUp}
        popUpType={popUpType}
        onSave={onSaveDeliveryPreferences}
      />
    </div>
  )
}

export default SetPreferences
