import React from 'react'
import PropTypes from 'prop-types'
import { Carousel } from 'react-bootstrap'
import { breakpoints } from 'globalStyles'
import styled from 'styled-components'

const StyledCarousel = styled.div`
  width: 260.75px;

  @media only screen and ${breakpoints.desktop}, ${breakpoints.tablet} {
    width: 250px;
  }
  .carousel-indicators [data-bs-target] {
    height: 5px;
  }
`

const ImageCarousel = ({ images }) => {
  const hideControls = images.length > 1
  return (
    <StyledCarousel>
      <Carousel
        interval={null}
        indicators={hideControls}
        controls={hideControls}
        keyboard={hideControls}
        touch={hideControls}
        nextLabel="Next"
        prevLabel="Previous"
        aria-live="polite"
      >
        {images.map((image, idx) => (
          <Carousel.Item key={`photoPOD-image-${idx}`}>
            <img
              className="d-block w-100"
              src={image}
              alt={`item ${idx + 1} of ${images.length}`}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </StyledCarousel>
  )
}

ImageCarousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
}

ImageCarousel.defaultProps = {
  images: [],
}

export default ImageCarousel
