import { React } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import InputField from 'reusableComponents/InputField/InputField'
import styled from 'styled-components'
import { PropTypes } from 'prop-types'
import { breakpoints } from 'globalStyles'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
`

const InfoStyledSpan = styled.span`
  font-size: 12px;
  letter-spacing: 0em;
  float: left;
  text-align: left;
  align-items: left;

  @media screen and ${breakpoints.sm_above} {
    font-size: 14px;
  }
`
const DeliveryInstructions = ({ onValueChangeHandler, instructions, inputCount }) => {
  const { t } = useTranslation(['translation'])

  return (
    <Container>
      <InputField
        id="additional-delivery-instructions"
        inputCount={inputCount}
        onChange={onValueChangeHandler}
        value={instructions}
        maxLength={150}
        label={t('deliveryPreferencesSummary.instruction_type')}
      />

      <InfoStyledSpan>
        <Trans t={t} i18nKey="deliveryPreferencesSummary.instructions" />
      </InfoStyledSpan>
    </Container>
  )
}

DeliveryInstructions.propTypes = {
  onValueChangeHandler: PropTypes.func,
  instructions: PropTypes.string,
  inputCount: PropTypes.number,
}

DeliveryInstructions.defaultProps = {
  onValueChangeHandler: null,
  instructions: '',
  inputCount: 0,
}

export default DeliveryInstructions
