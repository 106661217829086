import React from 'react'
import Lottie from 'react-lottie'
import animationData from './data'

const LoadingTruck = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <div style={{ height: '100%' }}>
      <Lottie options={defaultOptions} />
    </div>
  )
}

export default LoadingTruck
