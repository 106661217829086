import styled from 'styled-components'
import { breakpoints } from 'globalStyles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-bottom: 42px;
  @media ${breakpoints.xxs} {
    max-width: 319px;
  }
`
export const ShipmentDetailsContainer = styled.div`
  position: relative;
  diplay: flex;
  justify-content: center;
  padding: 0px 16px;
  @media ${breakpoints.desktop} {
    width: 100%;
    justitfy-content: flex-start;
    padding: 0px 24px;
  }
`

export const ShippingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 580px;
  gap: 30px;
  @media ${breakpoints.desktop} {
    max-width: 85%;
    align-items: flex-start;
    width: 100%;
  }
`
export const ShippingInfoContainer = styled.div`
  display: grid;
  grid-gap: 30px;
  width: 100%;
  @media ${breakpoints.desktop} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${breakpoints.xxs} {
    max-width: 315px;
  }
`
export const StickyContainer = styled.div`
  display: flex;
  position: absolute;
  align-items: flex-end;
  top: 167px;
  right: 0px;
  height: 100%;
`

export const Sticky = styled.div`
  position: sticky;
  bottom: 300px;
`
