import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { faClockRotateLeft } from '@fortawesome/pro-regular-svg-icons'
import Button from 'react-bootstrap/Button'
import PopUp from 'reusableComponents/PopUp'
import FAIcon from 'reusableComponents/FAIcon'
import { getDOMEncodeString, getCookieExpireFromLocalStorage } from 'utils/helperFunctions'
import { colors } from 'globalStyles'
import useTimer from 'hooks/useTimer'
import { selectAllowRenewSession, selectHashedPin } from 'store/shipping/shippingSelectors'
import { renewSession, endSession } from 'store/shipping/shippingSlice'

const SessionTimeOutModal = ({ sessionActive }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const allowRenewSession = useSelector(selectAllowRenewSession)
  const hashedPin = useSelector(selectHashedPin)

  const expTime = getCookieExpireFromLocalStorage(hashedPin) * 1000

  const warningTime = 5 * 60
  const { isExpired, isWarning } = useTimer(sessionActive, expTime, warningTime)

  const removeSession = useCallback(() => {
    dispatch(endSession())
    setOpen(false)
  }, [dispatch])
  const extendSession = () => {
    dispatch(renewSession())
    setOpen(false)
  }
  useEffect(() => {
    if (sessionActive) {
      if (allowRenewSession && isWarning) {
        setOpen(true)
      }
      if (isExpired) {
        removeSession()
      }
    }
  }, [isExpired, allowRenewSession, removeSession, sessionActive, isWarning])

  return (
    <PopUp
      show={open}
      primaryButton={
        <Button variant="secondary" onClick={removeSession}>
          {t('buttonActions.no')}
        </Button>
      }
      secondaryButton={
        <Button variant="primary" onClick={extendSession}>
          {t('buttonActions.yes')}
        </Button>
      }
      icon={getDOMEncodeString(<FAIcon icon={faClockRotateLeft} color={colors.primary_black} />)}
      title={t('sessionTimeOutModalHeading')}
      iconWidth="45px"
    >
      {t('sessionTimeOutModalText')}
    </PopUp>
  )
}

SessionTimeOutModal.propTypes = {
  sessionActive: PropTypes.bool.isRequired,
}

export default SessionTimeOutModal
