import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import GlobalStyle from 'globalStyles'
import Body from './Routes'

function App() {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <Body />
    </BrowserRouter>
  )
}

export default function WrappedApp() {
  return (
    <Suspense fallback="...is loading">
      <App />
    </Suspense>
  )
}
