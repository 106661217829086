import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import { breakpoints, colors } from 'globalStyles'
import DefaultCloseBtnSVG from 'assets/svg/default_close_btn.svg'
import HoverCloseBtnSVG from 'assets/svg/hover_close_btn.svg'

const StyledShowMoreModal = styled(Modal)`
  .modal-header {
    border-bottom: 0;
  }
  .modal-footer {
    border-top: 0;
  }
  .modal-backdrop.show {
    opacity: 0.25;
  }
  .modal-content {
    max-height: 596px;
    align-items: center;
  }
  .modal-body {
    padding: 7px;
    margin-bottom: 24px;
    overflow-y: auto;
    scrollbar-width: thin;
    width: 100%;
  }
  .modal-title {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-decoration: underline;
    line-height: 41px;
    color: ${colors.text_link};
  }
  .btn-close {
    opacity: 1;
    background: transparent url(${DefaultCloseBtnSVG}) 50%/24px auto no-repeat;
    @media ${breakpoints.lg_above} {
      background: transparent url(${DefaultCloseBtnSVG}) 50%/28px auto no-repeat;
    }
  }
  .btn-close:hover {
    background: transparent url(${HoverCloseBtnSVG}) 50%/24px no-repeat;
    @media ${breakpoints.lg_above} {
      background: transparent url(${HoverCloseBtnSVG}) 50%/28px no-repeat;
    }
  }
  .modal-header {
    width: 95%;
    display: flex;
    align-items: top;
    gap: 12px;
  }

  .headerIcon {
    width: 38px;
    margin-right: 20px;
    height: auto;
  }
`

const PYWShowMoreModal = ({ children, headerTitle, iconPath, size, show, onHide }) => {
  const handleClose = () => onHide(false)
  return (
    <StyledShowMoreModal size={size} show={show} onHide={handleClose} centered>
      {headerTitle !== null && (
        <Modal.Header closeButton>
          <Modal.Title>
            <img src={iconPath} alt="Popup Icone" className="headerIcon" />
            {headerTitle}
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
    </StyledShowMoreModal>
  )
}

PYWShowMoreModal.propTypes = {
  headerTitle: PropTypes.string,
  iconPath: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func,
}

PYWShowMoreModal.defaultProps = {
  headerTitle: '',
  size: 'md',
  onHide: null,
}

export default PYWShowMoreModal
