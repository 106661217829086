import { React } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Card } from 'react-bootstrap'
import { breakpoints, colors } from 'globalStyles'

const StyledCollectionCard = styled(Card)`
  display: inline;
  padding: 4px 0px;
  border-radius: 8px 0px;
  border: none !important;
  min-height: 69px;
  box-shadow: 0px 0px ${(props) => (props.shadow ? '8px' : '5px')} rgba(0, 0, 0, 0.2);

  .card-body {
    flex: unset;
    padding: 8px 0;
  }
  .card-text .card-title {
    font-size: 14px;
    line-height: 19px;
    @media ${breakpoints.lg_above} {
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
    }
  }
  .card-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: ${colors.primary_blue};

    @media ${breakpoints.sm_above} {
      font-size: 24px;
      margin-top: 5px;
    }
  }
  .card-subtitle {
    font-size: 12px;
    line-height: 28px;
    color: ${colors.secondary_black};
  }
  .card-text {
    display: table-cell;
  }
  .titleIconStyle {
    width: auto;
    height: 34px;
    @media ${breakpoints.sm_above} {
      width: auto;
      height: 44px;
    }
  }
  .toolTipIconStyle {
    width: 20px;
    height: 20px;
    @media ${breakpoints.sm_above} {
      width: 24px;
      height: 24px;
    }
  }
  .titleIconeContainerStyle {
    display: table-cell;
    padding-left: 10px;
    padding-right: 18px;
    vertical-align: top;

    @media ${breakpoints.sm_above} {
      padding-right: 18px;
    }
  }
`

const titleContentStyle = {
  display: 'table-cell',
}

const CollectionCard = ({
  titleIconPath,
  title,
  subtitle,
  displayContent,
  actionButton,
  collectionArray,
  children,
}) => {
  const displayStyle = {
    margin: '0 auto',
    width: '98%',
    paddingLeft: '10px',
  }

  const collectionStyle = {
    margin: '0 auto 10px',
    width: '100%',
  }

  const showMoreStyle = {
    left: '50%',
    position: 'relative',
    transform: 'translateX(-50%)',
  }

  const containerStyle = {
    margin: '-15px 0 0',
    padding: '0',
  }

  return (
    <StyledCollectionCard>
      <Card.Body>
        <div className="titleIconeContainerStyle">
          <img src={titleIconPath} alt={`${title} Icon`} className="titleIconStyle" />
        </div>
        <div style={titleContentStyle}>
          <Card.Title>{title}</Card.Title>
          <Card.Subtitle>{subtitle}</Card.Subtitle>
        </div>
      </Card.Body>
      {displayContent !== null && (
        <Card.Body style={displayStyle}>
          <div id="collectioncard_display_content">{displayContent}</div>
        </Card.Body>
      )}
      {actionButton !== null && actionButton}
      <Card.Body style={collectionStyle}>
        <div style={containerStyle}>{collectionArray}</div>
        <div style={showMoreStyle}>{children}</div>
      </Card.Body>
    </StyledCollectionCard>
  )
}

CollectionCard.propTypes = {
  displayContent: PropTypes.node,
  titleIconPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  actionButton: PropTypes.node,
  collectionArray: PropTypes.arrayOf(PropTypes.node).isRequired,
  children: PropTypes.node,
}

CollectionCard.defaultProps = {
  subtitle: '',
  displayContent: null,
  actionButton: null,
  children: null,
}

export default CollectionCard
