/* eslint-disable camelcase */
import img_0 from './images/img_0.jpg'
import img_1 from './images/img_1.jpg'
import img_2 from './images/img_2.jpg'
import img_3 from './images/img_3.jpg'
import img_4 from './images/img_4.jpg'
import img_5 from './images/img_5.jpg'
import img_6 from './images/img_6.jpg'
import img_7 from './images/img_7.jpg'
import img_8 from './images/img_8.jpg'
import img_9 from './images/img_9.jpg'

export default {
  v: '5.4.4',
  fr: 29.9700012207031,
  ip: 0,
  op: 60.0000024438501,
  w: 313,
  h: 195,
  nm: 'loading_purolator',
  ddd: 0,
  assets: [
    { id: 'image_0', w: 481, h: 9, u: '', p: img_0, e: 0 },
    { id: 'image_1', w: 239, h: 9, u: '', p: img_1, e: 0 },
    { id: 'image_2', w: 246, h: 14, u: '', p: img_2, e: 0 },
    { id: 'image_3', w: 49, h: 23, u: '', p: img_3, e: 0 },
    { id: 'image_4', w: 397, h: 86, u: '', p: img_4, e: 0 },
    { id: 'image_5', w: 13, h: 13, u: '', p: img_5, e: 0 },
    { id: 'image_6', w: 22, h: 22, u: '', p: img_6, e: 0 },
    { id: 'image_7', w: 22, h: 7, u: '', p: img_7, e: 0 },
    { id: 'image_8', w: 22, h: 5, u: '', p: img_8, e: 0 },
    { id: 'image_9', w: 31, h: 6, u: '', p: img_9, e: 0 },
    {
      id: 'comp_0',
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          nm: 'building/loading_purolator.ai',
          cl: 'ai',
          refId: 'image_4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 1,
                  s: [197.115, 59.61, 0],
                  e: [-197.135, 59.61, 0],
                  to: [-65.708, 0, 0],
                  ti: [65.708, 0, 0],
                },
                { t: 60.0000024438501 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [198.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 300.00001221925,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 2,
          nm: 'building/loading_purolator.ai',
          cl: 'ai',
          parent: 1,
          refId: 'image_4',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [592.75, 43, 0], ix: 2 },
            a: { a: 0, k: [198.5, 43, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          ip: 0,
          op: 300.00001221925,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 2,
      ty: 1,
      nm: 'White Solid 2',
      td: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [156.5, 97.5, 0], ix: 2 },
        a: { a: 0, k: [156.5, 97.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      hasMask: true,
      masksProperties: [
        {
          inv: false,
          mode: 'f',
          pt: {
            a: 0,
            k: {
              i: [
                [1.879, 0],
                [0, 0],
                [0, 1.879],
                [0, 0],
                [-1.879, 0],
                [0, 0],
                [0, -1.879],
                [0, 0],
              ],
              o: [
                [0, 0],
                [-1.879, 0],
                [0, 0],
                [0, -1.879],
                [0, 0],
                [1.879, 0],
                [0, 0],
                [0, 1.879],
              ],
              v: [
                [272.06, 158.289],
                [40.69, 158.289],
                [37.275, 154.874],
                [37.275, 154.127],
                [40.69, 150.711],
                [272.06, 150.711],
                [275.475, 154.127],
                [275.475, 154.874],
              ],
              c: true,
            },
            ix: 1,
          },
          o: { a: 0, k: 100, ix: 3 },
          x: { a: 0, k: 0, ix: 4 },
          nm: 'Mask 1',
        },
      ],
      sw: 313,
      sh: 195,
      sc: '#fffefe',
      ip: 0,
      op: 300.00001221925,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 2,
      nm: 'tree1',
      tt: 1,
      refId: 'image_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 66, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [30.521, 155.679, 0],
              e: [85.771, 155.679, 0],
              to: [9.208, 0, 0],
              ti: [-9.208, 0, 0],
            },
            { t: 60.0000024438501 },
          ],
          ix: 2,
        },
        a: { a: 0, k: [232.684, 4.492, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 300.00001221925,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 2,
      nm: 'progress bar inside',
      refId: 'image_1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [37.688, 154.951, 0], ix: 2 },
        a: { a: 0, k: [-0.149, 4.039, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 300.00001221925,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: 'progress bar',
      refId: 'image_2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [157.475, 154.85, 0], ix: 2 },
        a: { a: 0, k: [122.965, 6.813, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 300.00001221925,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 2,
      nm: 'TRUCK',
      refId: 'image_3',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [153.287, 137.069, 0], ix: 2 },
        a: { a: 0, k: [24.26, 11.407, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 300.00001221925,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 0,
      nm: 'building Comp 1',
      refId: 'comp_0',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [156.5, 106.5, 0], ix: 2 },
        a: { a: 0, k: [115, 60.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 230,
      h: 121,
      ip: 0,
      op: 300.00001221925,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 2,
      nm: 'SUN1',
      refId: 'image_5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
              e: [387],
            },
            { t: 301.000012259981 },
          ],
          ix: 10,
        },
        p: { a: 0, k: [228.619, 54.742, 0], ix: 2 },
        a: { a: 0, k: [6.262, 6.262, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 300.00001221925,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 2,
      nm: 'SUN2',
      parent: 12,
      refId: 'image_6',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [6.261, 6.262, 0], ix: 2 },
        a: { a: 0, k: [10.89, 10.89, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 300.00001221925,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 2,
      nm: 'CLOUD1',
      refId: 'image_7',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [92.181, 58.254, 0], ix: 2 },
        a: { a: 0, k: [10.643, 3.229, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 300.00001221925,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 2,
      nm: 'CLOUD2',
      refId: 'image_8',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [84.003, 51.935, 0], ix: 2 },
        a: { a: 0, k: [10.642, 2.407, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 300.00001221925,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 16,
      ty: 2,
      nm: 'CLOUD3',
      refId: 'image_9',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [81.789, 55.686, 0], ix: 2 },
        a: { a: 0, k: [15.109, 2.818, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 300.00001221925,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 17,
      ty: 1,
      nm: 'White Solid 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [156.5, 97.5, 0], ix: 2 },
        a: { a: 0, k: [156.5, 97.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      sw: 313,
      sh: 195,
      sc: '#fffefe',
      ip: 0,
      op: 300.00001221925,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
}
