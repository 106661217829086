import { useEffect, useState } from 'react'

const useDetectCansAndLocker = (location) => {
  const { pathname } = location
  const [isCansAndLocker, setIsCansAndLocker] = useState(false)

  useEffect(() => {
    if (pathname.includes('cans') || pathname.includes('locker')) {
      setIsCansAndLocker(true)
    }
  }, [pathname])

  return isCansAndLocker
}

export default useDetectCansAndLocker
