import styled from 'styled-components'
import { breakpoints, colors } from 'globalStyles'

const Container = styled.div`
  padding: 32px 16px 0 16px;
  margin-bottom: 20px;
  @media screen and ${breakpoints.tablet} {
    padding: 32px 25px 0 25px;
  }
  @media screen and ${breakpoints.lg_above} {
    padding: 48px 46px 0 46px;
  }
`
const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: ${(props) => (props.hashSegment ? '65px' : '0px')};

  @media screen and ${breakpoints.lg_above} {
    padding-top: ${(props) => (props.hashSegment ? '96px' : '0px')};
  }
  .heading {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px;
    color: ${colors.secondary_black};
    @media screen and ${breakpoints.tablet} {
      font-size: 32px;
      line-height: 43.58px;
      margin-bottom: 40px;
    }
    @media screen and ${breakpoints.lg_above} {
      font-size: 32px;
      line-height: 43.58px;
      margin-bottom: 55px;
    }
  }
`
export { Container, Section }
