import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import PopUp from 'reusableComponents/PopUp'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
import DropOffLocation from 'components/DropOffLocation/DropOffLocation'
import DropOffLocationIcon from 'assets/svg/house-building-black.svg'
import BuzzerCode from 'components/BuzzerCode'
import DeliveryInstructionss from 'components/DeliveryInstructionss'
import BuzzerCodeIcon from 'assets/svg/buzer-code-pop-up.svg'
import DeliveryInstructionIcon from 'assets/svg/additional-instruction-pop-up.svg'
import { Form } from 'react-bootstrap'
import { selectDeliverySummary } from 'store/shipping/shippingSelectors'
import { useSelector } from 'react-redux'

const SetPreferencesPopUp = ({ show, onHide, popUpType, onSave }) => {
  const [validated, setValidated] = useState(false)

  const { t } = useTranslation()
  const { deliveryLocation, buzzerCode, additionalDeliveryInstructions } =
    useSelector(selectDeliverySummary)
  const [loc, setLoc] = useState(deliveryLocation)
  const [buzzCode, setBuzzCode] = useState(buzzerCode)
  const [instr, setInstr] = useState(additionalDeliveryInstructions)
  const { remoteSignaturePreference } = useSelector(selectDeliverySummary)
  const [inputCount, setInputCount] = useState(0)

  const preferencesValueChangeHandler = (event) => {
    setLoc(event.target.value)
  }

  const buzzCodeValueChangeHandler = (event) => {
    const buzCode = event.target.value
    const re = /^[0-9a-zA-Z\b]+$/
    if (buzCode !== '' && !re.test(buzCode)) return
    setBuzzCode(buzCode)
  }

  const instrCodeValueChangeHandler = (event) => {
    setInstr(event.target.value)
    setInputCount(event.target.value.length)
  }

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault()
      const form = event.currentTarget

      if (form.checkValidity() === false) {
        event.stopPropagation()
      }
      setValidated(true)
      onSave(instr, buzzCode, loc, remoteSignaturePreference)
      onHide()
    },
    [instr, buzzCode, loc, remoteSignaturePreference, onSave, onHide]
  )

  const modalType = {
    dropOffLocation: {
      title: t('delivery_preferences.drop_off_location'),
      icon: DropOffLocationIcon,
      saveText: t('buttonActions.save'),
      cancelText: t('buttonActions.cancel'),
      component: (
        <DropOffLocation
          id="dropOffLocation"
          onValueChangeHandler={preferencesValueChangeHandler}
          selectedItem={loc}
        />
      ),
      isSaveDisabled: loc === deliveryLocation,
      onCancel: () => {
        setLoc(loc)
        onHide()
      },
    },
    buzzerCode: {
      title: t('delivery_preferences.buzzer_code'),
      icon: BuzzerCodeIcon,
      saveText: t('buttonActions.save'),
      cancelText: t('buttonActions.cancel'),
      component: (
        <BuzzerCode
          id="buzzerCode"
          buzzerCode={buzzCode}
          onValueChangeHandler={buzzCodeValueChangeHandler}
        />
      ),
      isSaveDisabled: buzzCode === buzzerCode,
      onCancel: () => {
        setBuzzCode(buzzerCode)
        onHide()
      },
    },
    deliveryInstructions: {
      title: t('delivery_preferences.additional_instructions'),
      icon: DeliveryInstructionIcon,
      saveText: t('buttonActions.save'),
      cancelText: t('buttonActions.cancel'),
      component: (
        <DeliveryInstructionss
          id="additionalInstr"
          inputCount={inputCount}
          onValueChangeHandler={instrCodeValueChangeHandler}
          instructions={instr}
        />
      ),
      isSaveDisabled: instr === additionalDeliveryInstructions,
      onCancel: () => {
        setInstr(additionalDeliveryInstructions)
        onHide()
        if (additionalDeliveryInstructions) {
          setInputCount(additionalDeliveryInstructions.length)
        } else {
          setInputCount(0)
        }
      },
    },
  }
  // Set relative modelData based on the Type.
  const modalData = modalType[popUpType]

  return (
    <Form id="preferenceOptionsForm" noValidate validated={validated} onSubmit={handleSubmit}>
      <PopUp
        show={show}
        primaryButton={
          <Button variant="secondary" onClick={modalData.onCancel}>
            {modalData.cancelText}
          </Button>
        }
        secondaryButton={
          <Button
            type="submit"
            variant="primary"
            form="preferenceOptionsForm"
            disabled={modalData.isSaveDisabled}
          >
            {modalData.saveText}
          </Button>
        }
        icon={modalData.icon}
        title={modalData.title}
        onHide={modalData.onCancel}
      >
        {modalData.component}
      </PopUp>
    </Form>
  )
}

SetPreferencesPopUp.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  popUpType: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default SetPreferencesPopUp
