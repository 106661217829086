import { colors } from 'globalStyles'
import Switch from '@mui/material/Switch'
import { styled } from '@mui/material/styles'

const StyledSwitch = styled(Switch)(({ language, theme }) => ({
  width: 62,
  height: 21,
  [theme.breakpoints.up('md')]: {
    width: 80,
    height: 27,
  },
  padding: 0,
  borderRadius: '8px',
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
  '& .MuiSwitch-switchBase': {
    padding: '2px 1px 0px 2px',
    '& .MuiSwitch-input': {
      width: '500%',
      left: '-200%',
    },
    '&.Mui-checked': {
      transform: 'translateX(41px)',
      [theme.breakpoints.up('md')]: {
        transform: 'translateX(52px)',
      },
      color: `${colors.primary_white}`,
      '& + .MuiSwitch-track': {
        backgroundColor: `${colors.primary_green}`,
        opacity: 1,
        background: 'rgba(97, 163, 10, 0.9)',
        borderRadius: '8px',
        border: `${colors.primary_green}`,
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 17,
        height: 17,
        lineHeight: '11px !important',
        background: `${colors.primary_white}`,
        paddingTop: `${language === 'en' ? '0px' : '0.3px'}`,
        [theme.breakpoints.up('md')]: {
          width: 23,
          height: 22,
          lineHeight: '15px !important',
          paddingTop: `1.5px`,
        },
        boxShadow: 'none !important',
        '&:before': {
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: `${language === 'en' ? '8px' : '7.1px'}`,
          content: `${language === 'en' ? "'Yes'" : "'Oui'"}`,
          color: `${colors.primary_black}`,
          paddingLeft: `${language === 'en' ? '2px' : `2px`}`,
          [theme.breakpoints.up('md')]: {
            fontSize: `${language === 'en' ? '10px' : '10px'}`,
            paddingLeft: `${language === 'en' ? '3.2px' : '2.7px'}`,
          },
        },
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 17,
    height: 17,
    lineHeight: '11px !important',
    [theme.breakpoints.up('md')]: {
      width: 23,
      height: 22,
      lineHeight: '16px !important',
    },
    background: `${colors.primary_black}`,
    boxShadow: 'none !important',
    '&:before': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: `${language === 'en' ? '8px' : '7.1px'}`,
      content: `${language === 'en' ? "'No'" : "'Non'"}`,
      color: `${colors.primary_white}`,
      paddingLeft: `${language === 'en' ? '2.8px' : `1.1px`}`,
      paddingTop: `${language === 'en' ? '0px' : '0.3px'}`,
      [theme.breakpoints.up('md')]: {
        fontSize: '10px',
        paddingLeft: `${language === 'en' ? '4.5px' : '1px'}`,
        paddingTop: '2px',
      },
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: `${colors.primary_white}`,
    opacity: 1,
    borderRadius: '8px',
    border: `1px solid ${colors.primary_black}`,
  },
}))

export { StyledSwitch }
