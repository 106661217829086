import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Card } from 'react-bootstrap'
import { breakpoints, colors } from 'globalStyles'

const StyledContainCard = styled(Card)`
  display: inline;
  padding: 4px 10px;
  border-radius: 8px 0px;
  min-height: 69px;
  box-shadow: 0px 0px ${(props) => (props.shadow ? '8px' : '5px')} rgba(0, 0, 0, 0.2);
  width: 100%;
  .card-body {
    display: table;
    padding: 4px 1rem;
  }
  .card-text .card-title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    @media ${breakpoints.lg_above} {
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
    }
  }
  .card-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: ${colors.secondary_black};
    @media ${breakpoints.sm_above} {
      font-size: 24px;
      line-height: 33px;
    }
  }
  .card-subtitle {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: ${colors.secondary_black};
    @media ${breakpoints.tablet} {
      font-size: 20px;
      line-height: 28px;
    }
  }
  .card-text {
    display: table-cell;
  }
  .titleIconStyle {
    width: auto;
    height: 24px;
    @media ${breakpoints.tablet} {
      width: auto;
      height: 28px;
    }
    @media ${breakpoints.lg_above} {
      width: 32px;
      height: 33px;
    }
  }
  .toolTipIconStyle {
    width: 20px;
    height: 20px;
    @media ${breakpoints.sm_above} {
      width: 24px;
      height: 24px;
    }
  }
  .titleIconeContainerStyle {
    display: table-cell;
    padding-right: 18px;
    vertical-align: top;
    @media ${breakpoints.lg_above} {
      padding-right: 11px;
      padding-top: 3px;
    }
    @media ${breakpoints.tablet} {
      padding-right: 18px;
      padding-top: 3px;
    }
  }
`

const toolTipIconeContainerStyle = {
  display: 'table-cell',
  paddingRight: '18px',
  verticalAlign: 'top',
}

const titleContentStyle = {
  display: 'table-cell',
}

const ContainCard = ({
  titleIconPath,
  title,
  subtitle,
  actionContent,
  bankDraftToolTipIconPath,
  bankDraftToolTip,
  warningToolTip,
  warningToolTipIconPath,
}) => {
  return (
    <StyledContainCard>
      <Card.Body>
        <div className="titleIconeContainerStyle">
          <img src={titleIconPath} alt="Title Icon" className="titleIconStyle" />
        </div>
        <div style={titleContentStyle}>
          <Card.Title>{title}</Card.Title>
          <Card.Subtitle>{subtitle}</Card.Subtitle>
        </div>
      </Card.Body>
      {actionContent !== null && (
        <Card.Body>
          <div id="containcard_action_content">{actionContent}</div>
        </Card.Body>
      )}
      {bankDraftToolTip !== '' && (
        <Card.Body>
          <div style={toolTipIconeContainerStyle}>
            <img
              src={bankDraftToolTipIconPath}
              alt="Bank Draft Tool Tip Icon"
              className="toolTipIconStyle"
            />
          </div>
          <Card.Text>{bankDraftToolTip}</Card.Text>
        </Card.Body>
      )}
      {warningToolTip !== '' && (
        <Card.Body>
          <div style={toolTipIconeContainerStyle}>
            <img
              src={warningToolTipIconPath}
              alt="Warning Tool Tip Icon"
              className="toolTipIconStyle"
            />
          </div>
          <Card.Text>{warningToolTip}</Card.Text>
        </Card.Body>
      )}
    </StyledContainCard>
  )
}

ContainCard.propTypes = {
  actionContent: PropTypes.node,
  titleIconPath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  bankDraftToolTipIconPath: PropTypes.string,
  bankDraftToolTip: PropTypes.string,
  warningToolTip: PropTypes.string,
  warningToolTipIconPath: PropTypes.string,
}

ContainCard.defaultProps = {
  subtitle: '',
  actionContent: null,
  bankDraftToolTipIconPath: '',
  bankDraftToolTip: '',
  warningToolTip: '',
  warningToolTipIconPath: '',
}

export default ContainCard
