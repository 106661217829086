import { useState, useEffect } from 'react'
import { getDifferenceInSeconds } from 'utils/helperFunctions'

const useTimer = (start, expiryTime, warningTime) => {
  const [countDown, setCountDown] = useState(1)
  const [runTimer, setRunTimer] = useState(start)

  const timeRemaining = getDifferenceInSeconds(expiryTime, Date.now())

  useEffect(() => {
    let timerId
    if (runTimer) {
      setCountDown(timeRemaining)
      timerId = setInterval(() => {
        setCountDown((prev) => prev - 1)
      }, 1000)
    } else if (!runTimer) {
      clearInterval(timerId)
    }

    return () => {
      clearInterval(timerId)
    }
  }, [runTimer, timeRemaining])

  useEffect(() => {
    if (countDown <= 0 && runTimer) {
      setRunTimer(false)
      setCountDown(0)
    }
  }, [countDown, runTimer])

  const toggleTimer = () => setRunTimer(!runTimer)

  const seconds = String(countDown % 60).padStart(2, 0)
  const minutes = String(Math.floor(countDown / 60)).padStart(2, 0)
  const isExpired = countDown < 1
  const isWarning = countDown <= warningTime && countDown > 1
  return { minutes, seconds, toggleTimer, isExpired, isWarning }
}

export default useTimer
