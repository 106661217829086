import styled from 'styled-components'
import { colors } from 'globalStyles'

const UNDERLINE_PX = 5

const DropdownWrapper = styled.div`
  position: relative;
  transition: border-bottom 250ms;
  border-top: ${UNDERLINE_PX}px solid transparent;
  border-bottom: ${UNDERLINE_PX}px solid ${colors.primary_white};
  &:hover {
    border-bottom: ${UNDERLINE_PX}px solid
      ${(props) => (props.menuItemSelected ? colors.primary_white : colors.secondary_light_blue)};
  }

  &.show {
    border-bottom: ${UNDERLINE_PX}px solid ${colors.primary_blue};
  }
  &.show > .dropdownMenu {
    opacity: 1;
    visibility: visible !important;
    transform: translate(0, 5px);
    height: inherit;
  }
`

const DropdownHeaderWrapper = styled.div`
  height: 100%;
  width: 100%;
`

const DropdownHeaderButton = styled.a`
  background-color: unset;
  border: unset;
  padding: unset;
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
  color: ${colors.primary_black};
  &:hover,
  &:active {
    color: ${colors.primary_black};
  }
`
const DropdownLinkStyle = styled(DropdownHeaderButton)`
  height: 32px;
  padding: 0px 16px;
  width: 100%;
  text-align: left;
  background-color: ${colors.primary_white};
  display: inline-block;
  pointer-events: inherit;

  &:hover {
    background-color: ${colors.dropdown_hover_light_blue};
  }
  &:focus-within,
  &:active {
    background-color: ${colors.primary_blue};
    color: ${colors.primary_white};
  }
  &:first-child {
    border-radius: 4px 4px 0px 0px;
    padding: 4px 16px 0 16px;
  }
  &:last-child {
    border-radius: 0px 0px 4px 4px;
  }
  &:only-child {
    border-radius: 4px;
  }
`

const DropdownMenu = styled.div`
  height: fit-content;
  min-width: 100%;
  width: max-content;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: 1056;
  transform: translate(0, 20px);
  transition: all 250ms;
  background-color: transparent;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  padding-top: 8px;
`

export {
  DropdownWrapper,
  DropdownHeaderButton,
  DropdownMenu,
  DropdownLinkStyle,
  DropdownHeaderWrapper,
}
