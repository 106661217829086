import { React, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { breakpoints, colors } from 'globalStyles'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Card } from 'react-bootstrap'
import { updateOptInSMSFlag } from 'api/shipping'
import { selectDeliverySummary } from 'store/shipping/shippingSelectors'
import { StyledSwitch } from './SmsToggle.style'

const StyledBaseCard = styled(Card)`
  display: flex;
  flex-direction: row !important;
  position: relative;
  padding: 8px 4px;
  border-radius: 8px 0px;
  min-height: 40px;
  width: 100%;
  background: ${colors.primary_white};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px 0px;
  .card-body {
    display: block;
  }
  .iconStyle {
    display: flex;
    position: relative;
  }
`

const StyledSpan = styled.span`
  font-size: 12px;
  color: ${colors.secondary_black};
  line-height: 16px;
  font-style: normal;
  font-weight: 400;
  display: table-cell;
  width: 90%;
  position: relative;
  vertical-align: top;
  padding: 7px 20px 0px 8.5px;
  @media ${breakpoints.desktop} {
    font-size: 14px;
    padding: 6.5px 0px 0px 5.5px;
  }
`

const StyledDiv = styled.div`
  display: table-cell;
`

const SmsToggle = ({ optedIn }) => {
  const { t, i18n } = useTranslation()
  const { shipmentId } = useSelector(selectDeliverySummary)
  const { language } = i18n
  const [check, setCheck] = useState(optedIn)
  const message = t('toggle_msg')
  const handleChange = () => {
    setCheck(!check)
  }
  useEffect(() => {
    updateOptInSMSFlag(shipmentId, check)
  }, [check, shipmentId])
  return (
    <StyledBaseCard>
      <Card.Body>
        <div style={{ display: 'table', width: '100%' }}>
          <div>
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              size="lg"
              color={colors.secondary_orange}
              style={{ padding: '5px 0px 0px 3.5px' }}
            />
          </div>
          <StyledSpan>{message}</StyledSpan>
          <StyledDiv>
            <StyledSwitch
              language={language}
              checked={check}
              disableRipple
              onChange={handleChange}
            />
          </StyledDiv>
        </div>
      </Card.Body>
    </StyledBaseCard>
  )
}

SmsToggle.propTypes = {
  optedIn: PropTypes.bool.isRequired,
}

export default SmsToggle
