import React, { useEffect, useState } from 'react'
import { getDoorKnockerInfo } from 'api/cans'
import { useParams } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { colors, breakpoints } from 'globalStyles'
import styled from 'styled-components'
import pywBannerEn from 'assets/svg/PYW_Banner_EN.svg'
import pywBannerFr from 'assets/svg/PYW_Banner_FR.svg'
import proofOfAddress from 'assets/svg/proof_of_address.svg'
import proofOfAddressFr from 'assets/svg/proof_of_address_fr.svg'
import driverLicense from 'assets/svg/driver_license.svg'
import driverLicenseFr from 'assets/svg/driver_license_fr.svg'
import LoadingTruck from 'reusableComponents/LoadingTruck'
import PropTypes from 'prop-types'
import { isEmpty, capitalize } from 'lodash'
import { format } from 'date-fns'
import { enCA as en, fr } from 'date-fns/locale'
import NotFound from 'pages/NotFound'
import Table from 'react-bootstrap/Table'
import CansServiceUnavailable from 'pages/CansServiceUnavailable'

const locales = { en, fr }

const Card = ({ title, subtitle, children }) => (
  <CardStyles>
    <div className="header-section">
      <div className="title">{title} </div>
      <div className="subtitle">{subtitle}</div>
    </div>
    {children}
  </CardStyles>
)

const FooterStyle = styled.div`
  background-color: #1f408f;
  padding: 20px;
  color: white;
  border-radius: 0 0 8px 14px;
  box-shadow: 1px 3px #ababab80;
  font-size: 14px;

  .large {
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .grid-styling {
    display: grid;
    gap: 14px;
  }

  .item-one {
    grid-column-start: 1;
    grid-row-start: 1;
  }

  .item-two {
    grid-column-start: 1;
    grid-row-start: 2;
  }

  .item-three {
    grid-column-start: 2;
    grid-row-start: 1;
  }

  .item-four {
    grid-column-start: 2;
    grid-row-start: 2;
  }
`
const DoorknockerHeader = styled.div`
  background-color: white;
  color: ${colors.primary_blue};
  position: relative;
  text-align: left;

  .title-image {
    width: 100%;
  }

  .title-text {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    display: inline-block;
    position: absolute;
    top: 5px;
    left: 5px;

    @media (${breakpoints.md_above}) {
      font-size: 32px;
      font-weight: 900;
      line-height: 30px;
      top: 11px;
      left: 42px;
    }
  }
`
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f7f7f7;
  box-shadow: 0 0 4px 3px ##b9b9b94d;
  align-items: center;
  gap: 15px;
  padding: 15px;

  p {
    margin: 0;
  }

  .title-padding {
    padding: 32px 0px 0px;
  }

  .small-text {
    font-size: 14px;
    color: #555555;
    margin: 0;
  }

  .padding-small-text {
    padding: 26px 0px 0px;
  }
  .small-text-eg {
    font-size: 14px;
    color: #555555;
    margin: 14px 0px;
    font-weight: 200;
  }

  .blue-small-text {
    color: #1f408f;
  }

  .address-proof-img {
    height: 20.93%;
    width: 40.06%;
    margin: 20px auto;
  }

  .govt-id-image {
    height: 14.64%;
    width: 47.01%;
    display: block;
    margin: 20px auto;
  }

  .extra-text {
    font-size: 14px;
    font-weight: 700;
    line-height: 12px;

    @media (${breakpoints.md_above}) {
      font-size: 20px;
      font-weight: 700;
      line-height: 25px;
    }
  }
`
const ImgSectionStyle = styled.div`
  .header {
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    color: #1f408f;
  }
  .subtext {
    font-size: 14px;
    color: #555555;
    margin: 14px 0px;
    font-weight: 200;
  }
`
const CardStyles = styled.div`
  background-color: white;
  border-radius: 14px;
  box-shadow: 1px 3px #ababab80;
  font-size: 14px;
  color: black;
  text-align: center;
  overflow: hidden;
  width: 100%;
  padding-top: 36px;

  &:first-child {
    margin-top: -20px;
    @media (${breakpoints.md_above}) {
      margin-top: -45px;
    }
    z-index: 1;
  }
  .title {
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    color: #1f408f;
  }

  .subtitle {
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
    color: black;
  }

  .header-section {
    margin-bottom: 24px;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-itmes: center;
  gap: 5px;
  font-size: 16px;
  .header {
    color: #555555;
    font-size: 14px;
  }
`

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-itmes: center;
  gap: 24px;
  padding: 36px;
  padding-top: 0px;
`

const Blue = styled.span`
  color: #1f408f;
`

const StyledRow = styled.tr`
  text-align: left;
`

const CardImgSection = ({ header, children, subtext }) => (
  <ImgSectionStyle>
    <div className="header">{header}</div>
    {children}
    <div className="subtext">{subtext}</div>
  </ImgSectionStyle>
)

const StepOne = ({ t, lang }) => {
  const addrImgToDisp =
    lang === 'fr' ? (
      <img className="address-proof-img" src={proofOfAddressFr} alt="proof-of-address-french-img" />
    ) : (
      <img className="address-proof-img" src={proofOfAddress} alt="proof-of-address-img" />
    )
  const licenseImgToDisp =
    lang === 'fr' ? (
      <img className="govt-id-image" src={driverLicenseFr} alt="driver-license-french-img" />
    ) : (
      <img className="govt-id-image" src={driverLicense} alt="driver-license-img" />
    )
  return (
    <StepContainer>
      <p className="small-text">
        <Trans t={t} i18nKey="get_id_text" components={[<Blue />]} />
      </p>
      <CardImgSection header={t('proof_of_address')} subtext={t('proof_of_address_eg_text')}>
        {addrImgToDisp}
      </CardImgSection>
      <CardImgSection header={t('gov_photo_id')} subtext={t('gov_photo_id_eg_text')}>
        {licenseImgToDisp}
      </CardImgSection>
    </StepContainer>
  )
}

const getStoreAddress = (storeAddress) => {
  const address1 = []
  address1.push(
    `${storeAddress.streetNumber} ${storeAddress.streetName} ${storeAddress.streetType}`
  )
  address1.push(`${storeAddress.municipalityName} ${storeAddress.provinceCode}`)
  address1.push(storeAddress.postalCode)
  const addr1 = address1.join(', ')
  return `${addr1}`
}

const formatTime = (time, locale) => {
  const [hrs, mins, secs = 0] = time.split(':')
  const date = new Date(2022, 1, 1, hrs, mins, secs)
  return format(date, 'p', { locale })
}

const formatTimeAsPerLang = (time, lang) => {
  let formattedTime = null
  try {
    if (time !== undefined) {
      if (lang === 'en') {
        formattedTime = format(new Date(`2023-01-01T${time}`), 'hh:mm a')
      } else if (lang === 'fr') {
        formattedTime = time.slice(0, -3)
      } else {
        const error = 'Language has value other than en or fr'
        throw error
      }
    }
  } catch (error) {
    console.error('Format Time As Per Language Failed Due To: ', error) // eslint-disable-line no-console
  }
  return formattedTime
}

const formatHrsOfOperation = (hrsOfOperation, lang) => {
  let output = []
  output = hrsOfOperation.map((hrs) => ({
    ...hrs,
    day: hrs.day,
    open: formatTimeAsPerLang(hrs.open, lang),
    close: formatTimeAsPerLang(hrs.close, lang),
    status: hrs.status,
  }))
  return output
}

// getStoreHours
const getStoreHours = (storeHours, lang, t) => {
  const hrsOfOperation = []
  const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
  const storeHrs = storeHours.map((sh) => ({
    ...sh,
    day: `${sh.day.toLowerCase()}`,
  }))
  daysOfWeek.forEach((dayOfWeek) => {
    const foundObj = storeHrs.find((obj) => obj.day === dayOfWeek)
    if (foundObj) {
      if (foundObj.open && foundObj.close) {
        const obj = {
          day: capitalize(t(`${dayOfWeek}`)),
          open: foundObj.open,
          close: foundObj.close,
        }
        hrsOfOperation.push(obj)
      }
      if (foundObj.openException && foundObj.closeException) {
        const obj = {
          day: t('closed'),
          open: foundObj.openException,
          close: foundObj.closeException,
        }
        hrsOfOperation.push(obj)
      }
    } else {
      const obj = {
        day: capitalize(t(`${dayOfWeek}`)),
        status: t('closed'),
      }
      hrsOfOperation.push(obj)
    }
  })

  const hrsToDisplay = formatHrsOfOperation(hrsOfOperation, lang)
  return hrsToDisplay
}

const renderStoreHours = (hrs, index) => {
  const output = hrs.open ? hrs.open : hrs.status
  return (
    <StyledRow
      style={
        hrs.day === 'Closed' || hrs.day === 'Fermé' ? { lineHeight: '14px', height: '30px' } : {}
      }
      key={index}
    >
      <td>{hrs.day}</td>
      <td>{output}</td>
      <td>{hrs.close}</td>
    </StyledRow>
  )
}

const StepTwo = ({
  t,
  storeName,
  storeAddress,
  storeHours,
  pickUpTime,
  pickUpDate,
  storeId,
  cansInfo,
}) => {
  const storeNameToDisplay = storeName.replace(storeId, '')

  return (
    <>
      <StepContainer>
        <Section>
          <span className="header">{t('pick_up_location')}</span>
          <div>
            <span>{storeNameToDisplay}</span>
            <br />
            <span>{storeAddress}</span>
          </div>
        </Section>
        <Section>
          <span className="header">{t('hours_of_operation')}</span>
          <Table borderless size="sm" style={{ maxWidth: '278px', alignSelf: 'center' }}>
            <tbody>{storeHours.map(renderStoreHours)}</tbody>
          </Table>
        </Section>
        <Section>
          <span className="header">{t('pick_up_time')}</span>
          <span>{t('pick_up_date', { pickUpTime, pickUpDate })}</span>
        </Section>
        <p style={{ color: '#555555' }}>{t('hold_text')}</p>
      </StepContainer>
      <Footer cansInfo={cansInfo} t={t} />
    </>
  )
}

const Footer = ({ cansInfo, t }) => {
  return (
    <FooterStyle>
      <p>{t('tracking_num')}</p>
      <p className="large">{cansInfo.shipmentPin}</p>
      <div className="grid-styling">
        <span className="item-one">{t('num_of_items')}</span>
        <span className="large item-two">{cansInfo.packageCount || '-'}</span>
        <span className="item-three">{t('weight_of_items')}</span>
        <span className="large item-four">
          {cansInfo.shipmentWeight || '-'} {cansInfo.shipmentWeightUnit}
        </span>
      </div>
    </FooterStyle>
  )
}
const CansDoorKnocker = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'cansDoorKnocker' })
  const [cansInfo, setCansInfo] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [isServiceUnavailable, setServiceUnavailable] = useState(false)
  const { pinAndRetailCode } = useParams()
  const { i18n } = useTranslation()
  const { language: lang } = i18n
  const [pin, retailCodeParams] = pinAndRetailCode.split('&')
  const retailCode = retailCodeParams.split('=')[1]
  useEffect(() => {
    setLoading(true)
    getDoorKnockerInfo(pin, lang, retailCode)
      .then((res) => {
        if (res.status === 200) {
          if (isEmpty(res.data)) {
            setIsError(true)
            setCansInfo(null)
            setServiceUnavailable(false)
          } else {
            setCansInfo(res.data)
            setIsError(false)
            setServiceUnavailable(false)
          }
        } else {
          setServiceUnavailable(true)
          setIsError(false)
          setCansInfo(null)
        }
        setLoading(false)
      })
      .catch(() => {
        setServiceUnavailable(true)
        setIsError(false)
        setLoading(false)
      })

    return () => {
      setCansInfo(null)
      setIsError(false)
      setLoading(false)
      setServiceUnavailable(false)
    }
  }, [pin, lang, retailCode])

  if (loading) {
    return <LoadingTruck />
  }

  if (isError) {
    return <NotFound />
  }

  if (isServiceUnavailable) {
    return <CansServiceUnavailable />
  }
  const pickTime = formatTime(cansInfo.pickupTime, locales[lang])
  const pickDate = format(new Date(cansInfo.pickupDate), 'PPPP', {
    locale: locales[lang],
  })

  return (
    <>
      <DoorknockerHeader language={lang}>
        <img
          src={lang === 'en' ? pywBannerEn : pywBannerFr}
          alt="CANS Header"
          className="title-image"
        />
        <p className="title-text">{t('package_available')}</p>
      </DoorknockerHeader>
      <PageContainer>
        <p className="extra-text">
          {t('package_available_text')}
          {t('package_available_text_2')}
        </p>
        <Card title={t('step_1')} subtitle={t('get_id')}>
          <StepOne t={t} lang={lang} />
        </Card>

        <Card title={t('step_2')} subtitle={t('pick_up_package')}>
          <StepTwo
            t={t}
            storeName={cansInfo.retailStoreInfo.storeName}
            storeAddress={getStoreAddress(cansInfo.retailStoreInfo.storeAddress)}
            storeHours={getStoreHours(cansInfo.retailStoreInfo.storeHours, lang, t)}
            pickUpDate={pickDate}
            pickUpTime={pickTime}
            storeId={cansInfo.retailStoreInfo.storeId}
            cansInfo={cansInfo}
          />
        </Card>
      </PageContainer>
    </>
  )
}

StepOne.propTypes = {
  t: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
}
StepTwo.propTypes = {
  t: PropTypes.func.isRequired,
  storeName: PropTypes.string.isRequired,
  storeAddress: PropTypes.string.isRequired,
  storeHours: PropTypes.instanceOf(Array).isRequired,
  pickUpTime: PropTypes.string.isRequired,
  pickUpDate: PropTypes.string.isRequired,
  storeId: PropTypes.string.isRequired,
  cansInfo: PropTypes.shape({}).isRequired,
}

Footer.propTypes = {
  t: PropTypes.func.isRequired,
  cansInfo: PropTypes.shape({
    shipmentPin: PropTypes.string.isRequired,
    shipmentWeight: PropTypes.string.isRequired,
    shipmentWeightUnit: PropTypes.string.isRequired,
    packageCount: PropTypes.string.isRequired,
  }).isRequired,
}
Card.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
CardImgSection.propTypes = {
  header: PropTypes.string.isRequired,
  subtext: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default CansDoorKnocker
