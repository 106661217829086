import _ from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { changLang } from 'store/global/globalSlice'
import { colors } from 'globalStyles'
import styled from 'styled-components'

const LangButton = styled.a`
  border: none;
  background: none;
  text-decoration: none;
  background-color: ${colors.primary_white};
  color: ${colors.primary_black};
  font-weight: 600;
  padding: 1px 6px;

  &:hover {
    color: ${colors.primary_black};
    text-decoration: none;
    cursor: pointer;
  }
`

const LanguageSelector = () => {
  const dispatch = useDispatch()
  const { lang = 'en' } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const supportedLangs = i18n.options.supportedLngs
  const toLangs = _.reject(supportedLangs, (lcl) => lcl === lang || lcl === 'cimode')

  const changeLanguage = (locale) => {
    const newPath = pathname.replace(lang, locale)
    i18n.changeLanguage(locale)
    dispatch(changLang(locale))
    navigate(newPath)
  }

  const { t } = useTranslation('translation', { keyPrefix: 'aria_labels' })

  return toLangs.map((locale) => (
    <LangButton
      href="#"
      hrefLang={locale}
      aria-label={locale === 'fr' ? t('switch_lang_french') : t('switch_lang_english')}
      key={locale}
      onClick={() => changeLanguage(locale)}
    >
      {_.upperCase(locale)}
    </LangButton>
  ))
}

export default LanguageSelector
