import { createSelector } from '@reduxjs/toolkit'

export const selectLang = (state) => state.global.lang
export const selectVirtualAgentUrl = createSelector(selectLang, (lang) =>
  lang === 'fr'
    ? process.env.REACT_APP_VIRTUAL_AGENT_URL_FR
    : process.env.REACT_APP_VIRTUAL_AGENT_URL
)
export const selectHFPUVirtualAgentUrl = createSelector(selectLang, (lang) =>
  lang === 'fr'
    ? process.env.REACT_APP_HFPU_VIRTUAL_AGENT_URL_FR
    : process.env.REACT_APP_HFPU_VIRTUAL_AGENT_URL
)
