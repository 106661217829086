import { createGlobalStyle } from 'styled-components'

/*
Bootstrap breakpoints
Breakpoint  | Dimensions | Device  
-----------------------------------
X-Small	    | <576px     | phone
Small	      | ≥576px     | phone
Medium	  	| ≥768px     | tablet
Large	      | ≥992px     | tablet
Extra large	| ≥1200px    | desktop
XX large	  | ≥1400px    | desktop
-----------------------------------
*/

/*
Popup breakpoints
Breakpoint  | Dimensions | Device  
-----------------------------------
X-Small	    | <576px     | 90% | 
Small	      | ≥576px     | 500px
Medium	  	| ≥768px     | 500px
Large	      | ≥992px     | 50%
Extra large	| ≥1200px    | 50%
XX large	  | ≥1400px    | 800px
-----------------------------------
*/

/*
Button breakpoints
Breakpoint  | Dimensions | Device  
-----------------------------------
X-Small	    | <576px     | w: 126px, h: 50px
Small	      | ≥576px     | w: 126px, h: 50px
Medium	  	| ≥768px     | w: 137px, h: 55px
Large	      | ≥992px     | w: 137px, h: 55px
Extra large	| ≥1200px    | w: 137px, h: 55px
XX large	  | ≥1440px    | w: 137px, h: 55px
-----------------------------------
*/

export const breakpointValue = {
  xxs: 322,
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
  xlg: 1400,
}

export const breakpoints = {
  xxs: `(max-width: ${breakpointValue.xxs - 0.2}px)`,
  xs: `(max-width: ${breakpointValue.xs - 0.2}px)`,
  sm: `(min-width: ${breakpointValue.xs}px) and (max-width: ${breakpointValue.sm - 0.2}px)`,
  md: `(min-width: ${breakpointValue.sm}px) and (max-width: ${breakpointValue.md - 0.2}px)`,
  lg: `(min-width: ${breakpointValue.md}px) and (max-width: ${breakpointValue.lg - 0.2}px)`,
  xl: `(min-width: ${breakpointValue.lg}px)`,
  lg_under: `(max-width: ${breakpointValue.lg - 0.2}px)`,
  md_under: `(max-width: ${breakpointValue.md - 0.2}px)`,
  sm_under: `(max-width: ${breakpointValue.sm - 0.2}px)`,
  lg_above: `(min-width: ${breakpointValue.lg}px)`,
  md_above: `(min-width: ${breakpointValue.md}px)`,
  sm_above: `(min-width: ${breakpointValue.sm}px)`,
  phone: `(max-width: ${breakpointValue.sm - 0.2}px)`,
  tablet: `(min-width: ${breakpointValue.sm}px) and (max-width: ${breakpointValue.lg - 0.2}px)`,
  desktop: `(min-width: ${breakpointValue.lg}px)`,
  // large_desktop: `(min-width: ${breakpointValue.xlg}px)`,
  tablet_above: `(min-width: ${breakpointValue.sm}px)`,
}

export const breakpointFns = {
  xs: () => window.matchMedia(breakpoints.xs).matches,
  sm: () => window.matchMedia(breakpoints.sm).matches,
  md: () => window.matchMedia(breakpoints.md).matches,
  lg: () => window.matchMedia(breakpoints.lg).matches,
  xl: () => window.matchMedia(breakpoints.xl).matches,
  phone: () => window.matchMedia(breakpoints.phone).matches,
  tablet: () => window.matchMedia(breakpoints.tablet).matches,
  desktop: () => window.matchMedia(breakpoints.desktop).matches,
}

const GlobalStyle = createGlobalStyle`
  body {
  margin: 0;
  padding:0;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0,0,0,.25);
  -webkit-transition: color .2s ease;
  transition: color .2s ease;
}
::-webkit-scrollbar-track {
  background: rgba(0,0,0,.1);
  border-radius: 0;
}

.app {
 min-height: 100vh; /* will cover the 100% of viewport */
 display: flex;
 flex-direction: column;
 align-items: center;
 background-color: #fbfbfb;
}

.app-content {
    max-width: 1440px;
    width: 100%;
    flex: 1;
    background-color: #ffffff;
    margin-top: 62px;
}

.app-header {
  position: fixed;
  width: 100%;
  z-index: 11;
  display: block;
}

.app-footer {
  width: 100%;
}

`

// eslint-disable-next-line no-unused-vars
export const colors = {
  // These colors come from
  // https://www.figma.com/file/vzAQ6tNlPMd7TznndgEb1G/Purolator-Your-Way-Design?node-id=317%3A5297
  // under Purolator UI kit - Colors
  primary_white: '#ffffff',
  primary_black: '#000000',
  primary_red: '#e00000',
  primary_green: '#61A30A',
  primary_blue: '#001996',
  primary_dark_blue: '#001371',
  primary_grey: '#555555',
  primary_dark_grey: '#6c757d',
  secondary_light_blue: '#00a2c7',
  secondary_blue: '#1617c5',
  secondary_grey: '#495057',
  secondary_dark_grey: '#4d4d4d',
  secondary_light_grey: '#a2aaad',
  secondary_orange: '#ffa300',
  secondary_yellow: '#fbdd40',
  secondary_green: '#61a30a',
  secondary_magenta: '#c800a1',
  secondary_black: '#000000',
  secondary_red: '#DA322A',
  web_yellow: '#f8c81e',
  web_light_grey: '#d3d3d3',
  web_accessibility_min_grey: '#666666',
  web_lighter_grey: '#f5f6f6',
  web_dark_grey: '#495057',
  web_grey: '#c0c0c0',
  web_text_black: '#111111',
  web_text_light: '#ffffff',
  warning_red: '#e00000',
  text_link: '#001996',
  text_link_hover: '#0a38e6',
  dropdown_hover_light_blue: '#EBF0FD',
  translucent_light_blue: '#00a9ce59',
  medium_dark_shade_blue: '#22387d',
  green_blue_bg: '#508806',
  medium_orange: '#ff9500',
  dark_green: '#64A70B',
}

export default GlobalStyle
