import styled from 'styled-components'
import { breakpoints, colors } from 'globalStyles'

const StatusProgressBar = styled.div`
  display: flex;
  width: 100%;
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
  @media only screen and ${breakpoints.phone} {
    font-size: 14px;
    padding: 0px 16px;
    gap: 8px;
  }
  @media only screen and ${breakpoints.tablet} {
    gap: 19px;
  }
  @media only screen and ${breakpoints.lg_above} {
    gap: 25px;
  }
`
const StatusProgressBarItem = styled.div`
  text-align: center;
  color: ${colors.web_accessibility_min_grey};
  background-color: transparent;
  width: 100%;
  &.current {
    color: ${colors.secondary_black};
    font-weight: 600;
  }

  &.processed {
    &:before {
      background-color: ${colors.secondary_light_blue};
    }
  }
  &.done {
    &:before {
      background-color: ${colors.secondary_green} !important;
    }
  }

  &.issue {
    &:before {
      background-color: ${colors.secondary_red}!important;
    }
  }

  &:before {
    content: '';
    height: 10px;
    width: 100%;
    display: block;
    background-color: ${colors.web_light_grey};
    margin-bottom: 5px;
    @media only screen and ${breakpoints.lg_above} {
      height: 20px;
    }
  }

  &:first-child:before {
    border-radius: 25px 0px 0px 25px;
  }
  &:last-child:before {
    border-radius: 0px 25px 25px 0px;
  }
`

export { StatusProgressBar, StatusProgressBarItem }
