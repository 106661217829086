import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { colors } from 'globalStyles'

const HEADER_HEIGHT = '62px'

export const HeaderExpand = styled.div`
  position: absolute;
  top: ${HEADER_HEIGHT};
  height: 100vh;
  left: 0;
  width: ${(props) => (props.show ? '100vw' : '0px')};
  visibility: ${(props) => (props.show ? 'visibile' : 'hidden')};
  transition: width 300ms, visibility 300ms;
  background-color: ${colors.primary_white};
  overflow: hidden;
`
export const HeaderNavLink = styled(Link)`
  color: ${(props) => (props.isActive ? colors.primary_white : colors.secondary_grey)};
  background-color: ${(props) => (props.isActive ? colors.primary_blue : colors.primary_white)};
  text-decoration: none;
  font-size: 20px;
  line-height: 28px;
  height: 40px;
  width: 250px;
  opacity: ${(props) => (props.isActive ? 'none' : '0.8')};
  text-wrap: none;
  &:hover {
    color: ${colors.primary_black};
    background-color: ${colors.dropdown_hover_light_blue};
  }
  &:active {
    background-color: ${colors.primary_blue};
    color: ${colors.primary_white};
  }
`

export const HamburgerButtonWrapper = styled.button`
  height: 36px;
  width: 36px;
  border-radius: 100%;
  border: unset;
  padding: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 250ms;
  background-color: ${(props) =>
    props.isClosed ? colors.primary_white : colors.medium_dark_shade_blue};
`

export const HamburgerLogoutButton = styled.button`
  padding-left: 40px;
  font-size: 24px;
  border: none;
  background: none;
  background-color: ${colors.primary_white};
  color: ${colors.primary_black};
`
