import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { breakpoints, colors } from 'globalStyles'

const StyledFontAwesome = styled(FontAwesomeIcon)`
  @media only screen and ${breakpoints.phone} {
    ${(props) =>
      props.$respSize.phone &&
      css`
        font-size: ${props.$respSize.phone.size};
      `}
  }
  @media only screen and ${breakpoints.tablet} {
    ${(props) =>
      props.$respSize.tablet &&
      css`
        font-size: ${props.$respSize.tablet.size};
      `}
  }
  @media only screen and ${breakpoints.lg_above} {
    ${(props) =>
      props.$respSize.desktop &&
      css`
        font-size: ${props.$respSize.desktop.size};
      `}
  }
`

const FAIcon = ({ icon, color, size, respSize }) => {
  return <StyledFontAwesome icon={icon} color={color} $respSize={respSize} size={size} />
}

const viewSizeProps = PropTypes.shape({
  size: PropTypes.string.isRequired,
})

const respSizePropTypes = {
  phone: viewSizeProps,
  tablet: viewSizeProps,
  desktop: viewSizeProps,
}

export const faIconsPropTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.object.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  respSize: PropTypes.shape(respSizePropTypes),
}

FAIcon.propTypes = faIconsPropTypes

FAIcon.defaultProps = {
  color: colors.primary_blue,
  size: 'xl',
  respSize: {
    phone: null,
    tablet: null,
    desktop: null,
  },
}
export default FAIcon
