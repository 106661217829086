import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import MenuDropdown from 'reusableComponents/MenuDropdown'

const NavBar = styled.div`
  display: flex;
  gap: 30px;
  height: 100%;
`

const NavigationMenuBar = ({ navItems }) => {
  const navigate = useNavigate()

  const handleNavMenuItemClick = (href) => {
    navigate(href, { replace: true })
  }

  const { t } = useTranslation('translation', { keyPrefix: 'aria_labels' })

  return (
    <nav aria-label={t('main_menu')}>
      <NavBar>
        {navItems.map((menu, i) => (
          <MenuDropdown
            key={`${menu.menuHeading}-${i}`}
            header={
              <MenuDropdown.Header title={menu.menuHeading}>{menu.menuHeading}</MenuDropdown.Header>
            }
          >
            {menu.menuItems.map(({ href, name }, ii) => (
              <MenuDropdown.Item
                key={`${menu.menuHeading}-${i}-${name}-${ii}`}
                clickHandler={() => handleNavMenuItemClick(href)}
              >
                {name}
              </MenuDropdown.Item>
            ))}
          </MenuDropdown>
        ))}
      </NavBar>
    </nav>
  )
}

const menuItemPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  }).isRequired
)

NavigationMenuBar.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      menuHeading: PropTypes.string.isRequired,
      menuItems: menuItemPropTypes.isRequired,
    }).isRequired
  ).isRequired,
}

export default NavigationMenuBar
