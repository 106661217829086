import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PopUp from 'reusableComponents/PopUp'
import { selectMyPreferencesInfo } from 'store/shipping/shippingSelectors'
import styled from 'styled-components'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
import GearIcon from 'assets/svg/gear-black.svg'
import { locations, businessLocations } from 'components/DropOffLocation/config'

const PreferencesContent = styled.div`
  margin: 25px;
  width: 75%;
  align-items: center;

  text-align: left;
  .grid-styling {
    display: grid;
    gap: 14px;
  }

  .item-one {
    grid-column-start: 1;
    grid-row-start: 1;
  }

  .item-two {
    grid-column-start: 2;
    grid-row-start: 1;
  }

  .item-three {
    grid-column-start: 1;
    grid-row-start: 2;
  }

  .item-four {
    grid-column-start: 2;
    grid-row-start: 2;
  }

  .item-five {
    grid-column-start: 1;
    grid-row-start: 3;
  }

  .item-six {
    grid-column-start: 2;
    grid-row-start: 3;
  }
`
const PreferencesSummaryContent = () => {
  const { t } = useTranslation()
  const { dropOffLoc, buzzerCode, delInstr } = useSelector(selectMyPreferencesInfo)
  const allLocations = [...locations, ...businessLocations]
  const savedLocation = _.find(allLocations, ['value', dropOffLoc])
  return (
    <PreferencesContent>
      <div className="grid-styling">
        <span className="item-one">{t('myPreferences.drop_off_text')}</span>
        <span className="item-two">{savedLocation ? t(savedLocation.label) : ''}</span>
        <span className="item-three">{t('myPreferences.buzzer_code')}</span>
        <span className="item-four">{buzzerCode}</span>
        <span className="item-five">{t('myPreferences.del_instr')}</span>
        <span className="item-six">{delInstr}</span>
      </div>
    </PreferencesContent>
  )
}

const PreferencesSummary = ({ show, onHide }) => {
  const { t } = useTranslation()
  return (
    <PopUp
      show={show}
      primaryButton={
        <Button variant="primary" onClick={onHide}>
          {t('buttonActions.close')}
        </Button>
      }
      icon={GearIcon}
      title={t('myPreferences.my_preferences')}
      onHide={onHide}
    >
      <PreferencesSummaryContent />
    </PopUp>
  )
}

PreferencesSummary.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
}

export default PreferencesSummary
