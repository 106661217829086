import { get } from 'lodash'
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import ShowForSizes from 'reusableComponents/ShowForSizes'
import HamburgerMenu from 'reusableComponents/HamburgerMenu'
import { LanguageSelector } from 'components/LanguageSelector'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectIsTerminalPresent,
  selectIsUserVerified,
  selectShippingInfo,
} from 'store/shipping/shippingSelectors'
import useCookie from 'hooks/useCookie'
import PurolatorLogo from 'reusableComponents/PurolatorLogo'
import { endSession } from 'store/shipping/shippingSlice'
import { useLocation } from 'react-router-dom'
import useDetectCansAndLocker from 'hooks/useDetectCansAndLocker'
import NavigationMenuBar from './NavigationMenuBar'
import {
  CenterContainer,
  HeaderContainer,
  HeaderLogoutButton,
  HeaderLogoutButtonWrapper,
  LanguageSelectorWrapper,
  RightContainer,
} from './Header.style'

const getNavItems = (t, shippingPin, lang, isTerminalPresent) => [
  {
    menuHeading: t('header.track'),
    menuItems: [
      {
        name: t('header.shipment_details'),
        href: `/rs/tr/${lang}/${shippingPin}`,
      },
    ],
  },
  {
    menuHeading: t('header.support'),
    menuItems: isTerminalPresent
      ? [
          {
            name: t('header.contact_us'),
            href: `/rs/tr/${lang}/support#contactUs`,
          },
          {
            name: t('header.faqs'),
            href: `/rs/tr/${lang}/support#faqs`,
          },
        ]
      : [
          {
            name: t('header.contact_us'),
            href: `/rs/tr/${lang}/support#contactUs`,
          },
        ],
  },
]

const getNavItemsForCansAndLocker = (t, lang) => [
  {
    menuHeading: t('header.support'),
    menuItems: [
      {
        name: t('header.contact_us'),
        href: `/cansAndLocker/dk/${lang}/support`,
      },
    ],
  },
]

const Header = ({ disableNavigation }) => {
  const { t, i18n } = useTranslation()
  const shippingInfo = useSelector(selectShippingInfo)
  const hashedPin = get(shippingInfo, 'hashedPin', '')

  const isTerminalPresent = useSelector(selectIsTerminalPresent)
  const [shippingPinCookie, updateShippingPinCookie] = useCookie('hashPin', hashedPin)
  const dispatch = useDispatch()
  const userVerified = useSelector(selectIsUserVerified)
  const location = useLocation()
  const onClickLogout = () => {
    dispatch(endSession())
  }
  const isCansAndLocker = useDetectCansAndLocker(location)

  useEffect(() => {
    if (hashedPin) {
      const SHIPPING_PIN_COOKIE_VALID_TIME = 2 * 24 * 60
      updateShippingPinCookie(hashedPin, SHIPPING_PIN_COOKIE_VALID_TIME)
    }
  }, [hashedPin, updateShippingPinCookie])

  return (
    <HeaderContainer>
      <PurolatorLogo lang={i18n.language}>Purolator, Homepage</PurolatorLogo>
      {!disableNavigation && (
        <>
          <CenterContainer>
            <ShowForSizes hideOnlyFor={['xs']}>
              <NavigationMenuBar
                navItems={
                  isCansAndLocker
                    ? getNavItemsForCansAndLocker(t, i18n.language)
                    : getNavItems(t, shippingPinCookie, i18n.language, isTerminalPresent)
                }
              />
            </ShowForSizes>
          </CenterContainer>

          <RightContainer>
            {userVerified && (
              <ShowForSizes hideOnlyFor={['xs']}>
                <HeaderLogoutButtonWrapper>
                  <HeaderLogoutButton onClick={onClickLogout}>
                    {t('header.logout')}
                  </HeaderLogoutButton>
                </HeaderLogoutButtonWrapper>
              </ShowForSizes>
            )}
            <LanguageSelectorWrapper>
              <LanguageSelector />
            </LanguageSelectorWrapper>
            <ShowForSizes showOnlyFor={['xs']}>
              <HamburgerMenu
                navItems={
                  isCansAndLocker
                    ? getNavItemsForCansAndLocker(t, i18n.language)
                    : getNavItems(t, shippingPinCookie, i18n.language, isTerminalPresent)
                }
                isUserVerified={userVerified}
                logoutLabel={t('header.logout')}
                onLogout={onClickLogout}
              />
            </ShowForSizes>
          </RightContainer>
        </>
      )}
    </HeaderContainer>
  )
}

Header.propTypes = {
  disableNavigation: PropTypes.bool,
}

Header.defaultProps = {
  disableNavigation: false,
}

export default Header
