import React from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { breakpoints, colors } from 'globalStyles'

const Heading = styled.h1`
  color: ${colors.primary_blue};
  text-align: center;
  font-size: 25px;
  line-height: 40px;
  font-weight: 400;
  @media ${breakpoints.tablet_above} {
    font-size: 30px;
  }
`

const Link = styled.a.attrs({
  target: '_blank',
})`
  color: ${colors.primary_blue};
`

const DeliveryPreferences = ({ t }) => {
  const preferences = t('delivery_preferences', { returnObjects: true })
  return (
    <ul type="disc">
      {preferences.map((pref, i) => {
        const { name, subs } = pref
        const nested = subs ? (
          <ul type="disc">
            {subs.map((sub, ii) => (
              <li key={`develivery-preference-sub-${ii}`}>{sub}</li>
            ))}
          </ul>
        ) : null
        return (
          <li key={`tc-${i}`}>
            {name}
            {nested}
          </li>
        )
      })}
    </ul>
  )
}

DeliveryPreferences.propTypes = {
  t: PropTypes.func.isRequired,
}

const TermsAndConditions = () => {
  const { t } = useTranslation(['termsAndConditions', 'translation'])
  const termsAndConditions = t('content', { returnObjects: true })
  const services = t('services', { returnObjects: true })

  const baseUrl = process.env.REACT_APP_FOOTER_DOMAIN
  const termsAndServicesLink = t('footer.links.TC_service', { domain: baseUrl, ns: 'translation' })
  const tAndCTranslations = t

  return (
    <div>
      <Heading>
        <Trans t={t} i18nKey="terms_and_conditions" />
      </Heading>
      <p>
        <Trans i18nKey="pyw_summary" t={t} />
      </p>
      <h5>{tAndCTranslations('service_includes')}</h5>
      <ul type="disc">
        {services.map((service, i) => (
          <li key={`service-${i}`}>{service}</li>
        ))}
        <DeliveryPreferences t={tAndCTranslations} />
      </ul>
      <ol type="1" style={{ listStylePosition: 'outside' }}>
        {termsAndConditions.map((tAndC, i) => {
          const { subs } = tAndC
          const nested = subs ? (
            <ol type="a">
              {subs.map((sub, ii) => (
                <li key={`tc-sub-${ii}`}>{sub.tc}</li>
              ))}
            </ol>
          ) : null
          return (
            <li key={`tc-${i}`}>
              <Trans
                components={[<Link href={termsAndServicesLink} />]}
                i18nKey={`content.${i}.tc`}
                t={t}
                ns="termsAndConditions"
              />
              {nested}
            </li>
          )
        })}
      </ol>
    </div>
  )
}

export default TermsAndConditions
