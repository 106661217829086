import purolatorLogoSVG from 'assets/svg/purolator-svg.svg'
import styled from 'styled-components'

const PurolatorLogo = styled.a.attrs((props) => ({
  href: `${process.env.REACT_APP_PUROLATOR_DOT_COM}/${props.lang}`,
}))`
  height: 20px;
  width: 150px;
  color: transparent;
  font-size: 14px;
  background: transparent url(${purolatorLogoSVG}) no-repeat center;
  &:hover {
    color: transparent;
  }
`
export default PurolatorLogo
