import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { breakpoints } from 'globalStyles'

const StyledCustomSVG = styled.img`
  @media only screen and ${breakpoints.phone} {
    ${(props) =>
      props.$respSize.phone &&
      css`
        height: ${props.$respSize.phone.h};
        width: ${props.$respSize.phone.w};
      `}
  }
  @media only screen and ${breakpoints.tablet} {
    ${(props) =>
      props.$respSize.tablet &&
      css`
        height: ${props.$respSize.tablet.h};
        width: ${props.$respSize.tablet.w};
      `}
  }
  @media only screen and ${breakpoints.desktop} {
    ${(props) =>
      props.$respSize.desktop &&
      css`
        height: ${props.$respSize.desktop.h};
        width: ${props.$respSize.desktop.w};
      `}
  }
`

const CustomSVG = ({ icon, respSize }) => {
  return <StyledCustomSVG src={icon} $respSize={respSize} />
}

const viewSizeProps = PropTypes.shape({
  h: PropTypes.string.isRequired,
  w: PropTypes.string.isRequired,
})

const respSizePropTypes = {
  phone: viewSizeProps,
  tablet: viewSizeProps,
  desktop: viewSizeProps,
}

export const customSVGPropTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.object.isRequired,
  respSize: PropTypes.shape(respSizePropTypes),
}

CustomSVG.propTypes = customSVGPropTypes

CustomSVG.defaultProps = {
  respSize: {
    phone: null,
    tablet: null,
    desktop: null,
  },
}
export default CustomSVG
