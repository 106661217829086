import _ from 'lodash'
import { React, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { breakpoints } from 'globalStyles'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectSelectedPin } from 'store/shipping/shippingSelectors'
import DropDown from './DropDown'

const SpanStyled = styled.span`
  padding: 0 0 0 4px;
  font-size: 14px;

  @media ${breakpoints.desktop} {
    font-size: 16px;
  }
`

const DropdownWithDisplay = ({ items, disabled, selectedValueHandler }) => {
  const [isDisabled] = useState(items.length > 0 ? disabled : true)
  const { t } = useTranslation()
  const activePin = useSelector(selectSelectedPin)

  const dropDownDivStyle = {
    paddingRight: '0',
  }

  const spanDivStyle = {
    padding: '0',
    display: 'flex',
    alignItems: 'center',
  }

  return (
    <div className="input-group mb-1">
      <div style={dropDownDivStyle}>
        <DropDown
          items={items}
          onSelectHandler={selectedValueHandler}
          isDisabled={isDisabled}
          value={activePin}
          id="multi-piece-shipment-pins-selector"
        />
      </div>
      <div style={spanDivStyle}>
        <SpanStyled>{`(${_.indexOf(items, activePin) + 1} ${t('of')} ${items.length})`}</SpanStyled>
      </div>
    </div>
  )
}

DropdownWithDisplay.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabled: PropTypes.bool,
  selectedValueHandler: PropTypes.func.isRequired,
}

DropdownWithDisplay.defaultProps = {
  disabled: false,
}

export default DropdownWithDisplay
