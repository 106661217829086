import React from 'react'
import PropTypes from 'prop-types'
import { DetailCard } from 'reusableComponents/Cards'
import ImageCarousel from 'reusableComponents/ImageCarousel'
import { faHouseBuilding } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const StyledPhotoPod = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
`

const PhotoPod = ({ photoPODImages }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'photoPod' })
  return (
    <DetailCard
      title={{
        faIcon: { icon: faHouseBuilding },
        name: t('packageDelivered'),
      }}
    >
      <StyledPhotoPod>
        <ImageCarousel images={photoPODImages} />
      </StyledPhotoPod>
    </DetailCard>
  )
}

PhotoPod.propTypes = {
  photoPODImages: PropTypes.arrayOf(PropTypes.string),
}

PhotoPod.defaultProps = {
  photoPODImages: [],
}

export default PhotoPod
