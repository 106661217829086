import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { PatternFormat } from 'react-number-format'
import { useTranslation, Trans } from 'react-i18next'
import { Button, Spinner, Alert } from 'react-bootstrap'
import { InputField } from 'reusableComponents/InputField'
import { resendOPT, verifyOTP, subscribe } from 'api/shipping'
import TermsAndConditions from 'components/TermsAndConditions'
import styled from 'styled-components'
import { breakpoints, colors } from 'globalStyles'
import Form from 'react-bootstrap/Form'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import { registerUser } from 'api/accounts'

const Heading = styled.h4`
  text-align: center;
  display: inline;
  font-size: 25px;
  @media ${breakpoints.desktop} {
    color: ${colors.primary_blue};
    display: block;
    font-size: 16px;
    line-height: 22px;
    width: 140px;
    margin-bottom: 20px;
  }
`

const CustomInput = React.forwardRef((props, ref) => {
  return (
    <FloatingLabel
      {...props}
      ref={ref}
      label={
        <span>
          Phone Number
          <span className="last-char">*</span>
        </span>
      }
    >
      <Form.Control {...props} ref={ref} className="phoneInput" />
    </FloatingLabel>
  )
})

const getVerificationCodeErrorMessage = (errorStatus, t) => {
  switch (errorStatus) {
    case 2:
      return t('subscription.verification_code_invalid_response')
    case 5:
      return t('verificationPopup.verification_code_expired_error')
    case 6:
      return t('subscription.verification_code_max_attempts_error')
    default:
      return t('subscription.general_error_message')
  }
}

const StyledVerifyContent = styled.div`
  font-family: Open Sans;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(17, 17, 17, 0);
  text-align: center;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;

  form {
    width: 90%;
    text-align: left;
    margin: 0 auto;
  }

  label,
  .get-estimates-form label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  .form-group {
    margin-top: 15px;
  }

  .alert {
    padding: 1rem 0;
    color: #e00000;
  }

  .heading {
    color: ${colors.primary_blue};
    font-size: 2rem;
    width: 100%;
    font-weight: normal;
  }

  input[type='text'],
  input[type='tel'] {
    border-radius: 5px;
    border-width: 3px;
    border-color: black;
    border-width: 1px;
    height: 3rem;
    text-align: left !important;
    width: 100%;
  }

  #OptInLabel {
    font-size: 0.8rem;
    position: relative;
  }
  .concents-checkbox {
    vertical-align: middle;
    margin-right: 0.5rem;
  }

  .form-control {
    padding: 0.5rem;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.25rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.5rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .btn-primary {
    width: 100%;
    color: white;
    background-color: #001996;
    border-color: #001996;
  }

  .btn-secondary {
    width: 100%;
    color: #001996;
    background-color: #ffffff;
    border: 1px solid #001996;
    margin-top: 10px;
  }

  .btn-secondary:hover {
    background-color: #ffffff;
    color: #0a38e6;
    border: 1px solid #0a38e6;
  }

  .btn-secondary:not(:disabled):not(.disabled):active {
    color: #001996;
    background-color: #ffffff;
    border: 2px solid #001996;
  }

  [type='submit']:not(:disabled) {
    cursor: pointer;
    -webkit-appearance: button;
  }
  .form-copy-error {
    color: #f00;
    border-color: #f00 !important;
  }
  .cta-box-col-2 div {
    max-width: 300px;
  }

  #firstname-error,
  #lastname-error,
  #phone-error,
  #optin-error {
    text-align: left;
    font-size: 11px;
    color: red;
  }
  .contacterror {
    color: #ff0004;
  }
  .contactnormal {
    color: #000000;
  }
  @media only screen and (min-width: 992px) {
    .cta-box-col-2 div {
      margin-left: 50px;
    }
  }
  .last-char {
    content: attr(data-last-char);
    /* modern browser */
    color: red !important;
  }
  .requiredFieldHeader {
    text-align: left;
    padding-left: 5%;
    font-size: 0.75rem;
    padding-bottom: 0.75rem;
    margin-top: 3rem;
  }

  .registerButton {
    margin-top: 3%;
  }
`

export default () => {
  const { t, i18n } = useTranslation()
  // 0: not submitted, 1: loading, 2: submitted, 3: subscribing, 4: success
  const [formStep, setFormStep] = useState(0)
  // 0: no error, 1: send verification code error, 2: verify verification code error,
  // 3: subscribe error, 4: invalid phone number, 5: expired verification code, 6: maximum verification code attempts reached
  // 7: already registered error
  const [errorStatus, setErrorStatus] = useState(0)
  const [sendAgainDisabled, setSendAgainDisabled] = useState(false)
  const [verifyDisabled, setVerifyDisabled] = useState(false)

  useEffect(() => {
    if (sendAgainDisabled) {
      const zeroAttemptsTimeout = setTimeout(() => {
        setSendAgainDisabled(false)
        setErrorStatus(0)
      }, 1000 * 30)

      return () => {
        clearTimeout(zeroAttemptsTimeout)
      }
    }

    if (verifyDisabled) {
      const maxAttemptsTimeout = setTimeout(() => {
        setVerifyDisabled(false)
        setErrorStatus(0)
      }, 1000 * 30)

      return () => {
        clearTimeout(maxAttemptsTimeout)
      }
    }

    return null
  }, [sendAgainDisabled, verifyDisabled])

  const {
    control,
    getValues,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const formatNumber = (num) => {
    return num.replace(/[^\d]/g, '')
  }

  const sendCode = async (values) => {
    const formattedNumber = formatNumber(values.phoneNumber)
    console.log('values', values)
    const userObject = {
      lang: i18n.language,
      phoneNumber: formattedNumber,
      email: values.email,
      optIn: values.optIn,
      firstName: values.firstName,
      lastName: values.lastName,
    }
    try {
      setFormStep(1)
      const registerResponse = await registerUser(userObject)
      const parsedResponse = JSON.parse(registerResponse.data.body)

      if (parsedResponse.responseCode === 'SUCCESS') {
        // We have an error here
        setFormStep(2)
      } else if (parsedResponse.responseCode === 'PHONE_EXISTS') {
        // Display the phone number already exists screen
      } else if (parsedResponse.responseCode === 'EMAIL_EXISTS') {
        // Display the email already exists screen
      }
    } catch (err) {
      console.log('registerResponse Error:', err)
    }
  }

  const onSubscribe = () => {
    const body = {
      phoneNumber: formatNumber(getValues('phoneNumber')),
      firstName: getValues('firstName'),
      lastName: getValues('lastName'),
    }

    subscribe(body)
      .then((result) => {
        if (result.status === 200 && result.data.optIn === true) {
          setFormStep(4)
          return true
        }
        setErrorStatus(3)
        setFormStep(2)
        return true
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('verification err', err)
        setErrorStatus(3)
        setFormStep(2)
      })
  }

  const verifyCode = (values) => {
    // Template syntax follows url-template https://www.npmjs.com/package/url-template
    const lang = i18n.language
    const verificationcode = values.verificationCode
    const phonenumber = values.phoneNumber
    const prn = `PYWWeb:firstname.lastname:${i18n.language}`

    setFormStep(3)
    verifyOTP(formatNumber(phonenumber), lang, verificationcode, prn)
      .then((result) => {
        if (result.status === 200 && result.data.status.success === 'true') {
          if (typeof result.data.data === 'undefined') {
            onSubscribe()
            return true
          }
          const response = result.data
          if (response.data[0].response === 'VERIFICATION_CODE_DOES_NOT_MATCH') {
            setErrorStatus(2)
            setFormStep(2)
            return true
          }
          if (response.data[0].response === 'VERIFICATION_CODE_DOES_NOT_EXISTS') {
            setErrorStatus(5)
            setFormStep(2)
            return true
          }
          if (response.data[0].response === 'MAX_VERIFICATIONCODE_VERIFICATION_ATTEMPTS') {
            setErrorStatus(6)
            setFormStep(2)
            setVerifyDisabled(true)
            return true
          }
        }

        setErrorStatus(3)
        setFormStep(2)
        return true
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('Failed to connect to API', err)
        setErrorStatus(3)
        setFormStep(2)
      })
  }

  const resendCodeHandler = () => {
    const lang = i18n.language
    const prn = `PYWWeb:firstname.lastname:${i18n.language}`

    setSendAgainDisabled(true)
    resendOPT(formatNumber(getValues('phoneNumber')), lang, prn)
      .then((result) => {
        if (result.status === 200 && typeof result.data.data !== 'undefined') {
          if (
            result.data.data.length > 0 &&
            result.data.data[0].response === 'MAX_VERIFICATIONCODE_RESEND_LIMIT'
          ) {
            setErrorStatus(6)
            setFormStep(2)
            setSendAgainDisabled(true)
            return true
          }
        }
        return true
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error('Failed to connect to API', err)
      })
  }

  const submitForm = async (fields) => {
    switch (formStep) {
      case 0:
        await sendCode(fields)
        break
      case 2:
        verifyCode(fields)
        break
      default:
        break
    }
  }

  const backButtonHandler = () => {
    if (formStep === 2 || formStep === 3) {
      setFormStep(0)
    }
  }
  return (
    <StyledVerifyContent>
      <Heading className="heading">{t('subscription.create_account')}</Heading>
      <h6 className="requiredFieldHeader">
        <span className="last-char">*</span> {t('subscription.required_field')}
      </h6>
      <form onSubmit={handleSubmit(submitForm)}>
        {(formStep === 0 || formStep === 1) && (
          <>
            <div className="form-group">
              <InputField
                type="text"
                name="firstName"
                label="First Name"
                aria-invalid={errors.firstName ? 'true' : 'false'}
                isRequired
                {...register('firstName', {
                  required: {
                    value: true,
                    message: t('subscription.first_name_empty_error'),
                  },
                  pattern: {
                    value: /^([a-zA-Z]+[\s-])*[a-zA-Z]+$/,
                    message: t('subscription.first_name_invalid_error'),
                  },
                  maxLength: {
                    value: 50,
                    message: t('subscription.first_name_invalid_error'),
                  },
                })}
              />
              <span role="alert" id="firstname-error">
                {errors.firstName?.message}
              </span>
            </div>
            <div className="form-group">
              <InputField
                type="text"
                name="lastName"
                aria-invalid={errors.lastName ? 'true' : 'false'}
                isRequired
                label="Last Name"
                {...register('lastName', {
                  required: {
                    value: true,
                    message: t('subscription.last_name_empty_error'),
                  },
                  maxLength: {
                    value: 50,
                    message: t('subscription.last_name_invalid_error'),
                  },
                  pattern: {
                    value: /^([a-zA-Z]+[\s-])*[a-zA-Z]+$/,
                    message: t('subscription.last_name_invalid_error'),
                  },
                })}
              />
              <span role="alert" id="lastname-error">
                {errors.lastName?.message}
              </span>
            </div>
            <div className="form-group">
              <Controller
                control={control}
                name="phoneNumber"
                label="First Name"
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: t('subscription.phone_number_invalid_error'),
                  },
                }}
                placeholder="Phone"
                render={({ field: { name, value, ref, onChange } }) => (
                  <PatternFormat
                    format="1 ### ### ####"
                    displayType="input"
                    type="tel"
                    name={name}
                    value={value}
                    getInputRef={ref}
                    onChange={onChange}
                    customInput={CustomInput}
                    placeholder="Phone Number"
                  />
                )}
              />
              <span role="alert" id="phone-error">
                {errors.phoneNumber?.message}
              </span>
            </div>
            <div className="form-group">
              <InputField
                type="text"
                name="email"
                aria-invalid={errors.email ? 'true' : 'false'}
                isRequired
                label={t('subscription.email')}
                {...register('email', {
                  required: {
                    value: true,
                    message: t('subscription.email_empty_error'),
                  },
                  maxLength: {
                    value: 50,
                    message: t('subscription.email_invalid_error'),
                  },
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: t('subscription.email_invalid_error'),
                  },
                })}
              />
              <span role="alert" id="phone-error">
                {errors.email?.message}
              </span>
            </div>
            <div className="form-group">
              <label id="OptInLabel" htmlFor="optin">
                <input
                  type="checkbox"
                  name="optIn"
                  value="true"
                  className="concents-checkbox"
                  aria-invalid={errors.optIn ? 'true' : 'false'}
                  {...register('optIn', {
                    required: {
                      value: true,
                      message: t('subscription.concents_empty_error'),
                    },
                  })}
                />
                <Trans
                  t={t}
                  i18nKey="subscription.concents_message"
                  components={[
                    <a
                      href={
                        i18n.language === 'en'
                          ? process.env.REACT_APP_PUROLATOR_PRIVACY_POLICY_URL
                          : process.env.REACT_APP_PUROLATOR_PRIVACY_POLICY_URL_FR
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('subscription.privacy_policy_text')}
                    </a>,
                    <TermsAndConditions />,
                  ]}
                />
              </label>
              <span role="alert" id="optin-error">
                {errors.optIn?.message}
              </span>
              {errorStatus === 1 ? (
                <Alert variant="light">{t('subscription.general_error_message')}</Alert>
              ) : null}
              {errorStatus === 4 ? (
                <Alert variant="light">{t('subscription.phone_number_invalid_error')}</Alert>
              ) : null}
              {errorStatus === 7 ? (
                <Alert variant="light">{t('subscription.phone_number_already_registered')}</Alert>
              ) : null}
            </div>
          </>
        )}
        {(formStep === 2 || formStep === 3) && (
          <>
            <div className="row">
              <div className="col">
                <p className="mb-4">
                  {t('subscription.verification_code_message', {
                    phoneNumber: formatNumber(getValues('phoneNumber')),
                  })}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="form-group">
                <label id="verificationLabel" htmlFor="verificationCode">
                  {t('subscription.verification_code')}
                </label>
                <input
                  type="text"
                  name="verificationCode"
                  aria-invalid={errors.verificationCode ? 'true' : 'false'}
                  {...register('verificationCode', {
                    required: {
                      value: true,
                      message: t('subscription.verification_code_empty_error'),
                    },
                    pattern: {
                      value: /^[0-9]*$/,
                      message: t('subscription.verification_code_invalid_error'),
                    },
                    maxLength: {
                      value: 6,
                      message: t('subscription.verification_code_invalid_error'),
                    },
                    minLength: {
                      value: 6,
                      message: t('subscription.verification_code_invalid_error'),
                    },
                  })}
                />
                <div id="firstname-error">{errors.verificationCode?.message}</div>
              </div>
            </div>
            <div className="row text-center">
              <div className="col">
                <p className="small">
                  {t('verificationPopup.send_again_message')}
                  {` `}
                  <Button variant="link" onClick={resendCodeHandler} disabled={sendAgainDisabled}>
                    {t('verificationPopup.send_again_button')}
                  </Button>
                </p>
              </div>
            </div>
            {errorStatus !== 0 ? (
              <Alert variant="light">{getVerificationCodeErrorMessage(errorStatus, t)}</Alert>
            ) : null}
          </>
        )}
        {formStep === 4 && (
          <section>
            <div className="row">
              <div className="col">
                <h5>{t('subscription.subscription_success_text')}</h5>
              </div>
            </div>
          </section>
        )}
        {(formStep === 0 || formStep === 1) && (
          <Button type="submit" className="btn-primary registerButton" disabled={verifyDisabled}>
            {formStep === 0 ? (
              t('subscription.register_button')
            ) : (
              <Spinner as="span" animation="border" size="md" role="status" aria-hidden="true" />
            )}
          </Button>
        )}
        {(formStep === 2 || formStep === 3) && (
          <Button type="submit" className="btn-primary" disabled={verifyDisabled}>
            {formStep === 2 ? (
              t('subscription.verify_button')
            ) : (
              <Spinner as="span" animation="border" size="md" role="status" aria-hidden="true" />
            )}
          </Button>
        )}
        {(formStep === 2 || formStep === 3) && (
          <Button className="btn-secondary" disabled={verifyDisabled} onClick={backButtonHandler}>
            {t('subscription.back_button')}
          </Button>
        )}
      </form>
    </StyledVerifyContent>
  )
}
