import _ from 'lodash'
import styled from 'styled-components'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { colors } from 'globalStyles'
import { useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import {
  selectAddressCorrectionInfo,
  selectIsShippingToCanadaOrUS,
  selectPackageStatus,
  selectIsUserVerified,
  selectAllowFlagsForPreferences,
  selectInterceptFlags,
  selectIsLeadPinDelivered,
} from 'store/shipping/shippingSelectors'
import { selectVirtualAgentUrl } from 'store/global/globalSelectors'
import { faBoxCircleCheck, faCircleInfo, faCircleCheck } from '@fortawesome/pro-regular-svg-icons'
// import constants, { ADDRESS_CORRECTION, STATUS } from 'constants.json'
import constants from 'constants.json'
import Button from 'react-bootstrap/Button'
import Verification from 'components/Verification'
import { DetailCard, Row } from 'reusableComponents/Cards'

const AddressCorrectionLink = styled.a.attrs({
  id: 'request-address-correction-link',
  target: '_blank',
  rel: 'noreferrer',
})`
  color: ${colors.primary_blue};
`

const Centered = styled.div`
  text-align: center;
  font-size: 14px !important;
`
const ViewAddressLink = styled(Button)`
  padding: 0;
  display: flex !important;
  margin: 30px auto 12px;
  font-size: 14px !important;
  line-height: 24px;
  &.btn-check:focus + &.btn,
  &.btn:focus {
    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 0%);
  }
`

const addressBuilder = (address) => {
  const addressOne = _.chain(address).get('address', '').lowerCase().startCase().value()
  const postalcode = _.get(address, 'postalcode', '')
  const province = _.get(address, 'province', '')
  const city = _.chain(address).get('city', '').lowerCase().startCase().value()

  const addressArray = _.compact([addressOne, city, province, postalcode])

  return addressArray.reduce((acc, curr, i) => {
    if (i === addressArray.length - 1) {
      return `${acc}${curr}`
    }
    return `${acc}${curr}, `
  }, '')
}

const Address = ({ heading, address }) => {
  return (
    <div>
      <span>{heading}</span>
      <div>{addressBuilder(address)}</div>
    </div>
  )
}

const HFPUComponent = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'hfpu_component' })
  const virtualAgentUrl = useSelector(selectVirtualAgentUrl)
  const addressCorrectionInfo = useSelector(selectAddressCorrectionInfo)
  const allowFlagsForPreferences = useSelector(selectAllowFlagsForPreferences)
  const interceptFlags = useSelector(selectInterceptFlags)
  const packageStatus = useSelector(selectPackageStatus)
  const isShippingToCanadaOrUS = useSelector(selectIsShippingToCanadaOrUS)
  const isVerifiedUser = useSelector(selectIsUserVerified)
  const [showVerificationPopUp, setShowVerificationPopUp] = useState(false)
  const { shipmentPin, holdForPickUp, requestedAddressCorrection, shipmentStatus } =
    addressCorrectionInfo
  const { allowHFPUChange, hasHFPUCompleted, hasHFPURequested, hasReachedRetailLocation } =
    allowFlagsForPreferences
  const { verifySession } = interceptFlags
  const addressCorrectionURL = `${virtualAgentUrl}&action=requesthold&pin=${shipmentPin}`
  const addressCorrectionStatus = _.get(holdForPickUp, 'addressCorrectionStatus', null)
  const newaddressCorrection = _.get(requestedAddressCorrection, 'addressCorrectionStatus', null)
  const ADDRESS_CORRECTION_COMPLETED = constants.ADDRESS_CORRECTION.COMPLETED
  const ADDRESS_CORRECTION_IN_PROGRESS = constants.ADDRESS_CORRECTION.IN_PROGRESS
  const isLeadPinDelivered = useSelector(selectIsLeadPinDelivered)
  const addressHeading =
    addressCorrectionStatus === constants.ADDRESS_CORRECTION.COMPLETED
      ? t('updated_shipment_address')
      : t('shipment_address')
  const notDeliveredTitle =
    addressCorrectionStatus === constants.ADDRESS_CORRECTION.IN_PROGRESS
      ? t('requested_shipment_address')
      : addressHeading
  const subTitle =
    addressCorrectionStatus === constants.ADDRESS_CORRECTION.IN_PROGRESS
      ? addressBuilder(holdForPickUp)
      : addressBuilder(holdForPickUp)
  const mainTitle =
    packageStatus === constants.STATUS.DELIVERED ? t('delivered_to') : notDeliveredTitle

  const VerificationPopUPClickHandler = () => {
    setShowVerificationPopUp(true)
  }
  const commonIconSize = {
    phone: { size: '20px' },
    tablet: { size: '24px' },
    desktop: { size: '24px' },
  }
  let disabledFlag = false

  if (
    newaddressCorrection === ADDRESS_CORRECTION_COMPLETED ||
    newaddressCorrection === ADDRESS_CORRECTION_IN_PROGRESS ||
    (shipmentStatus === 'Delivered' && !hasReachedRetailLocation)
  ) {
    disabledFlag = true
  }

  const iconColor = disabledFlag ? colors.secondary_light_grey : colors.primary_blue

  return (
    <DetailCard
      disabled={disabledFlag}
      title={{
        name: mainTitle,
        subTitle,
        faIcon: { icon: faBoxCircleCheck, color: iconColor },
      }}
    >
      {disabledFlag && verifySession && <Centered>{t('request_hfpu_message')}</Centered>}
      {allowHFPUChange && (
        <Centered>
          {verifySession ? (
            <Trans
              t={t}
              i18nKey="request_new_address"
              components={[<AddressCorrectionLink href={addressCorrectionURL} />]}
            />
          ) : (
            t('request_hfpu_message')
          )}
        </Centered>
      )}

      {hasHFPUCompleted && !hasReachedRetailLocation && (
        <Row
          faIcon={{
            icon: faCircleCheck,
            respSize: commonIconSize,
            color: colors.dark_green,
          }}
        >
          {t('update_address_request_success')}
        </Row>
      )}
      {hasReachedRetailLocation && !isLeadPinDelivered && (
        <Row
          faIcon={{
            icon: faCircleInfo,
            respSize: commonIconSize,
            color: colors.secondary_light_blue,
          }}
        >
          {t('retail_location')}
        </Row>
      )}
      {hasHFPURequested && !hasHFPUCompleted && (
        <Row
          faIcon={{
            icon: faCircleInfo,
            respSize: commonIconSize,
            color: colors.secondary_light_blue,
          }}
        >
          {t('address_correction_recieved')}
        </Row>
      )}

      {disabledFlag && !verifySession && (
        <Centered style={{ margin: '20px' }}>
          {' '}
          {}
          {t('view_full_address')}
        </Centered>
      )}

      {!isVerifiedUser && !disabledFlag && isShippingToCanadaOrUS && (
        <ViewAddressLink id="viewAddressBtn" variant="link" onClick={VerificationPopUPClickHandler}>
          {t('view_full_address')}
        </ViewAddressLink>
      )}
      <Verification show={showVerificationPopUp} onHide={setShowVerificationPopUp} />
    </DetailCard>
  )
}

Address.propTypes = {
  heading: PropTypes.string.isRequired,
  address: PropTypes.shape({
    address: PropTypes.string,
    postalcode: PropTypes.string,
    city: PropTypes.string,
    province: PropTypes.string,
  }).isRequired,
}

export default HFPUComponent
