import styled from 'styled-components'
import { colors } from 'globalStyles'

export const Menu = styled.button`
  height: 40px;
  width: 100%;
  padding: unset;
  border: unset;
  background-color: unset;
  text-align: left;
  display: flex;
  align-items: center;
  svg {
    margin-left: 15px;
    transform: rotate(0deg);
    transition: transform 250ms;
    transform: ${(props) => (props.active ? 'none' : 'rotate(-90deg)')};
    fill: ${(props) => (props.active ? colors.primary_blue : '')};
  }
  span {
    font-weight: 400;
    font-size: 26px;
    color: ${(props) => (props.active ? colors.primary_blue : colors.web_text_black)};
    white-space: nowrap;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 18px;
  margin-bottom: 18px;
`

export const Items = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  height: ${(props) => (props.isOpen ? props.height : '0px')};
  transition: all 500ms;
  padding-left: 37px;
  padding-right: 16.5px;
  margin-top: 20px;
`
