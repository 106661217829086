import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import QrCode from 'qrcode.react'

// eslint-disable-next-line react/prop-types
const DisplayText = ({ label, value }) => (
  <div style={{ marginTop: 3, fontSize: 16 }}>
    <span style={{ marginRight: 5, fontWeight: 'bold' }}>{`${label} :`}</span>
    <span style={{ color: '#555555' }}>{value}</span>
  </div>
)

const Locker = () => {
  const { pickupCode, shipmentPin } = useParams()
  const { t } = useTranslation()

  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        right: '0',
        width: '100%',
        height: 1,
        overflow: 'visible',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <QrCode value={pickupCode} includeMargin size={300} />
      <DisplayText label={t('pick_up_code')} value={pickupCode} />
      <DisplayText label={t('tracking_number')} value={shipmentPin} />
    </div>
  )
}

export default Locker
