import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { changLang } from 'store/global/globalSlice'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { colors } from 'globalStyles'

const LanguageButton = styled.a`
  border: unset;
  padding: unset;
  background-color: unset;
  text-decoration: none;
  color: ${colors.primary_white};

  &:hover {
    color: ${colors.primary_white};
    text-decoration: none;
    cursor: pointer;
  }
`

const FooterLanguageSelector = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  const currentLang = i18n.language
  const changeLanguage = (newLanguage) => {
    i18n.changeLanguage(newLanguage, () => {
      dispatch(changLang(newLanguage))
      navigate(pathname.replace(currentLang, newLanguage))
    })
  }

  return (
    <>
      <LanguageButton
        href="#"
        aria-label={t('aria_labels.switch_lang_english')}
        hrefLang="en"
        key="en"
        onClick={() => changeLanguage('en')}
      >
        {t('en_locale_long')}
      </LanguageButton>
      <LanguageButton
        href="#"
        aria-label={t('aria_labels.switch_lang_french')}
        hrefLang="fr"
        key="fr"
        onClick={() => changeLanguage('fr')}
      >
        {t('fr_locale_long')}
      </LanguageButton>
    </>
  )
}

export default FooterLanguageSelector
