import React from 'react'
import styled from 'styled-components'
import Button from 'react-bootstrap/Button'
import PropTypes from 'prop-types'
import { colors, breakpointValue } from 'globalStyles'

const StyledWrapper = styled.div`
  .btn-outline {
    background-color: ${colors.web_text_light};
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px 0px;
    width: 124px;
    height: 124px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: ${colors.secondary_black};
    position: relative;
    padding-top: 45%;
    @media only screen and (max-width: ${breakpointValue.lg}px) {
      font-size: 12px;
      line-height: 16px;
      width: 94px;
      height: 94px;
    }
  }
  .btn-outline:hover {
    text-decoration-line: underline;
    color: ${colors.text_link_hover};
  }
  .btn-outline:focus {
    border: 1px solid ${colors.primary_blue};
    text-decoration-line: underline;
    color: ${colors.text_link_hover};
    box-shadow: none;
  }
  .btn-outline:disabled {
    background-color: ${colors.web_grey};
    color: ${colors.primary_dark_grey};
    font-weight: 600;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
  }
  .icon {
    position: absolute;
    left: 27.82%;
    right: 27.82%;
    top: 6.45%;
    bottom: 60.48%;
    height: 40px;
    @media only screen and (max-width: ${breakpointValue.lg}px) {
      height: 25px;
      left: 31.55%;
      right: 31.55%;
      top: 9.52%;
      bottom: 59.52%;
    }
  }
  .checkmark_full,
  .checkmark_empty {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 3.51%;
    left: 3.51%;
    z-index: 1;
    @media only screen and (max-width: ${breakpointValue.lg}px) {
      width: 16px;
      height: 16px;
    }
  }
`

const TransitionWrapper = styled.div`
  #fade {
    -webkit-animation: cssAnimation ${(props) => props.time}s forwards;
    animation: cssAnimation ${(props) => props.time}s forwards;
  }
  #join {
    visibility: hidden;
    animation: type ${(props) => props.time}s forwards;
    animation-delay: ${(props) => props.time}s;
  }
  @keyframes cssAnimation {
    0% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes type {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    100% {
      visibility: visible;
    }
  }
  @-webkit-keyframes cssAnimation {
    0% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`

const SuccessTransition = (successIcon, storedIcon, stored, transitionTime) => {
  return (
    <StyledWrapper>
      <TransitionWrapper time={transitionTime}>
        <div id="fade">
          <img src={successIcon} alt="saved" className="checkmark_empty" />
        </div>
        <div id="join">
          {}
          {stored && <img src={storedIcon} alt="filled" className="checkmark_full" />}
          {}
        </div>
      </TransitionWrapper>
    </StyledWrapper>
  )
}

export const OutlineButton = ({
  icon,
  label,
  action,
  success,
  stored,
  successIcon,
  storedIcon,
  transitionTime,
  disable,
}) => {
  const handleEvent = () => action()
  return (
    <StyledWrapper>
      <Button variant="outline" onClick={handleEvent} disabled={disable}>
        {success && SuccessTransition(successIcon, storedIcon, stored, transitionTime)}
        {!success && stored && <img src={storedIcon} alt="filled" className="checkmark_full" />}
        <img src={icon} alt={`${label} icon`} className="icon" />
        {label}
      </Button>
    </StyledWrapper>
  )
}

OutlineButton.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  action: PropTypes.func,
  success: PropTypes.bool,
  stored: PropTypes.bool,
  successIcon: PropTypes.string,
  storedIcon: PropTypes.string,
  transitionTime: PropTypes.number,
  disable: PropTypes.bool,
}

OutlineButton.defaultProps = {
  action: null,
  success: false,
  stored: false,
  successIcon: '',
  storedIcon: '',
  transitionTime: 5.5,
  disable: false,
}

const Buttons = {}

export default Buttons
