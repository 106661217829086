import { useState, React } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import crypto from 'crypto-browserify'
import ShowMoreModal from 'reusableComponents/ShowMoreModal'
import Button from 'react-bootstrap/Button'
import TransitHistoryIconSVG from 'assets/svg/transit_history_icon.svg'
import { selectTransitHistory } from 'store/shipping/shippingSelectors'
import { CollectionCard } from 'reusableComponents/Cards'
import { colors, breakpoints } from 'globalStyles'
import History from 'components/History'

const StyledHistoryContent = styled.div`
  width: 100%;
  float: left;
  text-align: left;
  background: ${colors.primary_white};

  .heading {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    @media ${breakpoints.sm_above} {
      font-size: 18px;
    }
  }
  .subText {
    font-size: 12px;
    color: ${colors.primary_grey};
    font-weight: 400px;
    line-height: 16.34px;

    @media ${breakpoints.sm_above} {
      font-size: 14px;
      line-height: 20px;
    }
  }
`

const TransitHistory = () => {
  const { t } = useTranslation()
  const [showMoreModal, setShowMoreModal] = useState(false)
  const transitHistory = useSelector(selectTransitHistory)
  const circleTypeHandler = (id, desc, limit) => {
    if (id === 0 && desc !== 'Delivered') {
      return 2
    }
    if (id === limit) {
      return 1
    }
    return 0
  }

  const transitHistoryLimit = 5

  const histroyContent = transitHistory.map((th, i, arr) => {
    return (
      <History
        dateInfo={
          i === 0 || arr[i - 1].scanDateTime !== th.scanDateTime
            ? t('date_short', {
                date: `${th.scanDateTime}`,
              })
            : ''
        }
        descriptionInfo={
          <StyledHistoryContent>
            <div className="heading">{th.scanDesc}</div>
            <span className="subText">{`${th.terminal}, ${t('short_time_meridiem', {
              date: th.scanDateTime,
            })}`}</span>
          </StyledHistoryContent>
        }
        circleType={circleTypeHandler(i, th.scanDesc, -1)}
        key={crypto.randomBytes(5).toString('hex')}
      />
    )
  })

  const showMoreHandler = () => {
    setShowMoreModal(true)
  }

  const ulStyle = {
    margin: '0',
    padding: '0',
    listStyleType: 'none',
  }

  const collectionContent =
    histroyContent.length > transitHistoryLimit
      ? [
          ...histroyContent.slice(0, transitHistoryLimit),
          <History
            dateInfo=""
            descriptionInfo={
              <Button
                onClick={showMoreHandler}
                variant="link"
                style={{ height: '40px', width: '100px' }}
              >
                {t('packageDetails.see_more')}
              </Button>
            }
            circleType={circleTypeHandler(histroyContent.length, '', histroyContent.length)}
            key={crypto.randomBytes(5).toString('hex')}
          />,
        ]
      : histroyContent

  return (
    <CollectionCard
      title={t('packageDetails.transit_history')}
      titleIconPath={TransitHistoryIconSVG}
      collectionArray={collectionContent}
    >
      <ShowMoreModal
        headerTitle={t('packageDetails.transit_history')}
        iconPath={TransitHistoryIconSVG}
        show={showMoreModal}
        size="md"
        onHide={setShowMoreModal}
      >
        <ul style={ulStyle}>{histroyContent}</ul>
      </ShowMoreModal>
    </CollectionCard>
  )
}
export default TransitHistory
