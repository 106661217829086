import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import { colors } from 'globalStyles'
import { useTranslation } from 'react-i18next'
import MenuItem from './MenuItem'
import {
  HamburgerButtonWrapper,
  HamburgerLogoutButton,
  HeaderExpand,
  HeaderNavLink,
} from './HamburgerMenu.style'

const HamburgerMenu = ({ navItems, isUserVerified, logoutLabel, onLogout }) => {
  const [show, setShow] = useState(false)
  const onClickLogout = () => {
    onLogout()
    setShow(false)
  }

  const { t } = useTranslation('translation', { keyPrefix: 'aria_labels' })

  return (
    <>
      <HamburgerButtonWrapper
        aria-label={t('hamburger_menu_button')}
        onClick={() => setShow(!show)}
        isClosed={show}
      >
        {show ? (
          <CloseIcon style={{ color: colors.secondary_dark_grey }} />
        ) : (
          <MenuIcon style={{ color: colors.primary_white }} />
        )}
      </HamburgerButtonWrapper>
      <HeaderExpand show={show}>
        {navItems.map((menu, i) => (
          <MenuItem
            key={`${menu.menuHeading}-${i}`}
            header={menu.menuHeading}
            aria-label={t('menu_item')}
          >
            {menu.menuItems.map((item, ii) => (
              <HeaderNavLink
                aria-label={t('header_nav_link')}
                key={`${menu.menuHeading}-${i}-${item.name}-${ii}`}
                onClick={() => setShow(false)}
                to={item.href}
              >
                {item.name}
              </HeaderNavLink>
            ))}
          </MenuItem>
        ))}
        {isUserVerified && (
          <HamburgerLogoutButton onClick={onClickLogout}>{logoutLabel}</HamburgerLogoutButton>
        )}
      </HeaderExpand>
    </>
  )
}

const menuItemPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  }).isRequired
)

HamburgerMenu.propTypes = {
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      menuHeading: PropTypes.string.isRequired,
      menuItems: menuItemPropTypes.isRequired,
    }).isRequired
  ).isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  logoutLabel: PropTypes.string,
  onLogout: PropTypes.func,
}
HamburgerMenu.defaultProps = {
  logoutLabel: '',
  onLogout: null,
}
export default HamburgerMenu
