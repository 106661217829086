import _ from 'lodash'
import styled from 'styled-components'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { colors } from 'globalStyles'
import { useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import {
  selectAddressCorrectionInfo,
  selectIsShippingToCanadaOrUS,
  selectPackageStatus,
  selectIsUserVerified,
  selectAllowFlagsForPreferences,
} from 'store/shipping/shippingSelectors'
import { selectVirtualAgentUrl } from 'store/global/globalSelectors'
import { DetailCard } from 'reusableComponents/Cards'
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons'
import constants from 'constants.json'
import Button from 'react-bootstrap/Button'
import Verification from 'components/Verification'

const AddressCorrectionLink = styled.a.attrs({
  id: 'request-address-correction-link',
  target: '_blank',
  rel: 'noreferrer',
})`
  color: ${colors.primary_blue};
`

const Centered = styled.div`
  text-align: center;
  font-size: 14px !important;
  margin-top: 20px;
`
const ViewAddressLink = styled(Button)`
  padding: 0;
  display: flex !important;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
  font-size: 14px !important;
  line-height: 24px;
  &.btn-check:focus + &.btn,
  &.btn:focus {
    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 0%);
  }
`

const addressBuilder = (address) => {
  const addressOne = _.chain(address).get('address', '').lowerCase().startCase().value()
  const postalcode = _.get(address, 'postalcode', '')
  const province = _.get(address, 'province', '')
  const city = _.chain(address).get('city', '').lowerCase().startCase().value()

  const addressArray = _.compact([addressOne, city, province, postalcode])

  return addressArray.reduce((acc, curr, i) => {
    if (i === addressArray.length - 1) {
      return `${acc}${curr}`
    }
    return `${acc}${curr}, `
  }, '')
}

const Address = ({ heading, address }) => {
  return (
    <div>
      <span>{heading}</span>
      <div>{addressBuilder(address)}</div>
    </div>
  )
}

const AddressValidation = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'address_validation' })
  const virtualAgentUrl = useSelector(selectVirtualAgentUrl)
  const addressCorrectionInfo = useSelector(selectAddressCorrectionInfo)
  const packageStatus = useSelector(selectPackageStatus)
  const isShippingToCanadaOrUS = useSelector(selectIsShippingToCanadaOrUS)
  const isVerifiedUser = useSelector(selectIsUserVerified)
  const { hasHFPURequested } = useSelector(selectAllowFlagsForPreferences)
  const [showVerificationPopUp, setShowVerificationPopUp] = useState(false)
  const { shipmentPin, toAddress, requestedAddressCorrection, allowAddressCorrection } =
    addressCorrectionInfo
  const addressCorrectionURL = `${virtualAgentUrl}&action=addresscorrection&pin=${shipmentPin}`
  const addressCorrectionStatus = _.get(requestedAddressCorrection, 'addressCorrectionStatus', null)
  const ADDRESS_CORRECTION_COMPLETED = constants.ADDRESS_CORRECTION.COMPLETED
  const ADDRESS_CORRECTION_IN_PROGRESS = constants.ADDRESS_CORRECTION.IN_PROGRESS
  const STATUS_DELIVERED = constants.STATUS.DELIVERED
  const addressHeading =
    addressCorrectionStatus === ADDRESS_CORRECTION_COMPLETED
      ? t('updated_shipment_address')
      : t('shipment_address')
  const correctionStatusMsg = {
    [ADDRESS_CORRECTION_IN_PROGRESS]: t('address_correction_recieved'),
    [ADDRESS_CORRECTION_COMPLETED]: t('update_address_request_success'),
  }
  const notDeliveredTitle =
    addressCorrectionStatus === ADDRESS_CORRECTION_IN_PROGRESS
      ? t('requested_shipment_address')
      : addressHeading
  const subTitle =
    addressCorrectionStatus === ADDRESS_CORRECTION_IN_PROGRESS
      ? addressBuilder(requestedAddressCorrection)
      : addressBuilder(toAddress)
  const mainTitle = packageStatus === STATUS_DELIVERED ? t('delivered_to') : notDeliveredTitle

  const VerificationPopUPClickHandler = () => {
    setShowVerificationPopUp(true)
  }
  const iconColor = hasHFPURequested ? colors.secondary_light_grey : colors.primary_blue

  return (
    <DetailCard
      attentionTxt={
        requestedAddressCorrection &&
        correctionStatusMsg[requestedAddressCorrection.addressCorrectionStatus]
      }
      disabled={hasHFPURequested}
      title={{
        name: mainTitle,
        subTitle,
        faIcon: { icon: faLocationDot, color: iconColor },
      }}
    >
      {allowAddressCorrection && (
        <Centered>
          <Trans
            t={t}
            i18nKey="request_new_address"
            components={[<AddressCorrectionLink href={addressCorrectionURL} />]}
          />
        </Centered>
      )}
      {hasHFPURequested && <Centered>{t('request_new_address_nolink')}</Centered>}
      {hasHFPURequested && <Centered>{t('view_full_address')}</Centered>}
      {!hasHFPURequested && !isVerifiedUser && isShippingToCanadaOrUS && (
        <ViewAddressLink id="viewAddressBtn" variant="link" onClick={VerificationPopUPClickHandler}>
          {t('view_full_address')}
        </ViewAddressLink>
      )}
      <Verification show={showVerificationPopUp} onHide={setShowVerificationPopUp} />
    </DetailCard>
  )
}

Address.propTypes = {
  heading: PropTypes.string.isRequired,
  address: PropTypes.shape({
    address: PropTypes.string,
    postalcode: PropTypes.string,
    city: PropTypes.string,
    province: PropTypes.string,
  }).isRequired,
}

export default AddressValidation
