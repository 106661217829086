import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import FloatingLabel from 'react-bootstrap/FloatingLabel'
import Form from 'react-bootstrap/Form'

const CharacterLimit = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 14px 0 0;
  font-size: 10px;
  color: #676d75;
`

const StyledInput = styled.div`
  .last-char {
    content: attr(data-last-char);
    /* modern browser */
    color: red !important;
  }
`

const InputField = React.forwardRef((props, ref) => {
  const {
    id,
    label,
    maxLength,
    errorText,
    isInvalid,
    isValid,
    onChange,
    value,
    disabled,
    autoFocus,
    blur,
    autoComplete,
    isRequired,
    inputCount,
    ...rest
  } = props

  return (
    <StyledInput>
      <FloatingLabel
        id={id}
        label={
          isRequired ? (
            <span>
              {label}
              <span className="last-char">*</span>
            </span>
          ) : (
            label
          )
        }
      >
        <Form.Control
          type="text"
          id={id}
          value={value}
          onChange={onChange}
          isInvalid={isInvalid}
          aria-invalid={isInvalid}
          isValid={isValid}
          maxLength={maxLength}
          placeholder={`${label}*`}
          onBlur={blur}
          autoFocus={autoFocus}
          disabled={disabled}
          autoComplete={autoComplete}
          ref={ref}
          {...rest}
        />
        <Form.Control.Feedback type="invalid">{errorText}</Form.Control.Feedback>
        {inputCount ? (
          <CharacterLimit>
            {inputCount}/{maxLength}
          </CharacterLimit>
        ) : (
          ''
        )}
      </FloatingLabel>
    </StyledInput>
  )
})

InputField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  errorText: PropTypes.string,
  isInvalid: PropTypes.bool,
  isValid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  blur: PropTypes.func,
  autoComplete: PropTypes.string,
  inputCount: PropTypes.number,
  isRequired: PropTypes.bool,
}

InputField.defaultProps = {
  maxLength: 150,
  errorText: '',
  isInvalid: false,
  isValid: false,
  disabled: false,
  autoFocus: false,
  blur: () => {},
  autoComplete: 'on',
  inputCount: 0,
  isRequired: false,
}

export default InputField
