import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'
import Card from 'react-bootstrap/Card'
import { breakpoints, colors } from 'globalStyles'
import FAIcon, { faIconsPropTypes } from 'reusableComponents/FAIcon'
import CustomSVG, { customSVGPropTypes } from 'reusableComponents/CustomSVG'
import { faCircleInfo as faCircleInfoIcon } from '@fortawesome/pro-regular-svg-icons'

const StyledRow = styled.div`
  display: grid;
  align-items: top;
  margin-bottom: 12px;
  column-gap: 16px;
  row-gap: 16px;
  font-size: 14px;
  line-height: 19px;
  grid-template-columns: fit-content(40px) auto;
  @media ${breakpoints.tablet} {
    grid-template-columns: fit-content(40px) auto;
    font-size: 16px;
    line-height: 22px;
  }
  @media ${breakpoints.desktop} {
    grid-template-columns: fit-content(40px) auto;
  }
  .iconCell {
    justify-self: end;
    margin: 4px 0px;
  }
  .customCell {
    justify-self: end;
    align-self: center;
  }
  .textCell {
    align-self: center;
  }
  .textCell.flex {
    display: flex;
  }
  .subTitle {
    display: flex;
    align-items: center;
  }
`
export const Row = ({ faIcon, customIcon, children, flex }) => {
  if (!children) return null
  const getIcon = () => {
    if (faIcon)
      return (
        <div className="iconCell">
          <FAIcon {...faIcon} />
        </div>
      )
    if (customIcon)
      return (
        <div className="customCell">
          <CustomSVG {...customIcon} />
        </div>
      )
    return null
  }
  return (
    <StyledRow>
      {getIcon()}
      <div className={flex ? 'textCell flex' : 'textCell'}>{children}</div>
    </StyledRow>
  )
}

const Title = styled.div`
  font-weight: ${(props) => (props.subTitle ? 400 : 600)};
  font-size: 18px;
  line-height: 25px;
  color: ${(props) => (props.subTitle ? colors.secondary_black : colors.primary_blue)};
  @media ${breakpoints.tablet_above} {
    font-size: 20px;
    line-height: 27px;
  }
`

const StyledDetailCard = styled.div`
  display: flex;
  .customBackground {
    background-color: #d3d3d3;
    color: #a2aaad;
    pointer-events: none;
    display: flex !important;
  }
`
const DetailCard = ({ attentionTxt, children, title, disabled }) => {
  const className = disabled ? 'customBackground' : ''
  const titleColor = disabled ? '#a2aaad' : colors.primary_blue
  const subTitleColor = disabled ? '#a2aaad' : colors.secondary_black
  return (
    <StyledDetailCard>
      <Card body className={className}>
        {title && (
          <Card.Title>
            <Row
              faIcon={{
                respSize: {
                  phone: { size: '24px' },
                  tablet: { size: '28px' },
                  desktop: { size: '28px' },
                },
                ...title.faIcon,
              }}
            >
              <Title style={{ color: titleColor }}>{title.name}</Title>
              <Title className="subTitle" subTitle style={{ color: subTitleColor }}>
                {title.subTitle}
              </Title>
            </Row>
          </Card.Title>
        )}
        {children}
        {attentionTxt && (
          <Row
            faIcon={{
              icon: faCircleInfoIcon,
              color: colors.primary_red,
              respSize: {
                phone: { h: '20px' },
                tablet: { h: '24px' },
                desktop: { h: '24px' },
              },
            }}
          >
            {attentionTxt}
          </Row>
        )}
      </Card>
    </StyledDetailCard>
  )
}

Row.propTypes = {
  faIcon: PropTypes.shape(faIconsPropTypes),
  customIcon: PropTypes.shape(customSVGPropTypes),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  flex: PropTypes.bool,
}
Row.defaultProps = {
  faIcon: null,
  customIcon: null,
  flex: false,
}
DetailCard.propTypes = {
  title: PropTypes.shape({
    name: PropTypes.string.isRequired,
    subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    faIcon: PropTypes.shape(faIconsPropTypes),
  }),
  disabled: PropTypes.bool,
  attentionTxt: PropTypes.string,
  children: PropTypes.node.isRequired,
}

DetailCard.defaultProps = {
  title: null,
  attentionTxt: null,
  disabled: false,
}

export default DetailCard
